import React from "react";
import autoBind from "react-autobind";
import { Link, Text, View, StyleSheet, Page, Image } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import { Subtitle, SubtitleBlack, Paragraph, styles } from "../PdfReportComponents/Shared.jsx";
class Disclaimer extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        const MidSection = styled.View`
            margin-top: 60%;
        `;

        const BottomSection = styled.View`
            top: 15%;
        `;
        return (
            <Page size="A4" style={styles.customPages}>
                <MidSection style={{}}>
                    <Subtitle>DISCLAIMER</Subtitle>
                    <Paragraph>
                        While every effort has been made to ensure that this document and the
                        sources of information used herein are free of error, the authors: Are not
                        liable for, the accuracy, currency and reliability of any information
                        provided in this publication; Make no express or implied representation of
                        warranty that any estimate of forecast will be achieved or that any
                        statement as to the future matters contained in this publication will prove
                        correct; Expressly disclaim any and all liability arising from the
                        information contained in this report including, without, errors in, or
                        omissions contained in the information; Except so far as liability under any
                        statute cannot be excluded, accept no responsibility arising in any way from
                        errors in, or omissions contained in the information; Do not represent that
                        they apply any expertise on behalf of the reader or any other interested
                        party; Accept no liability for any loss or damage suffered by any person as
                        a result of that person, of any other person, placing any reliance on the
                        contents of this publication; Assume no duty of disclosure or fiduciary duty
                        to any interested party.
                    </Paragraph>
                </MidSection>
                <BottomSection>
                    <Paragraph>
                        © Copyright XDI Pty Ltd, {new Date().getFullYear()} This work is copyright XDI Pty Ltd. All
                        material contained in this work is copyright XDI Pty Ltd except where a
                        third party source is indicated. XDI Pty Ltd and CLIMATE RISK PTY LTD
                        maintain the intellectual property rights for all of the tools used in this
                        project. XDI is a registered Trademark of XDI Pty Ltd.
                    </Paragraph>
                </BottomSection>

                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        );
    }
}

export default Disclaimer;
