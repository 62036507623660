import React from "react";
import autoBind from "react-autobind";
import { Link, Text, View, StyleSheet, Page, Image } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import {
    Subtitle,
    SubtitleBlack,
    SubtitleLarge,
    Paragraph,
    styles,
    Br1,
} from "../PdfReportComponents/Shared.jsx";
class Introduction extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        const BottomSection = styled.View`
            margin-top: 0%;
        `;
        const RedBorderSection = styled.View`
            border-style: solid;
            border-top-width: 4px;
            border-right-width: 4px;
            border-bottom-width: 4px;
            border-left-width: 4px;
            border-top-color: #b51218;
            border-right-color: #b51218;
            border-left-color: #b51218;
            border-bottom-color: #b51218;
            margin-left: auto;
            margin-right: auto;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            top: 15%;
        `;
        return (
            <React.Fragment>
                <Page size="A4" style={styles.customPages}>
                    <View
                        style={[
                            styles.redBackground,
                            { paddingVertical: 10, paddingHorizontal: 10, marginBottom: 10 },
                        ]}
                    >
                        <SubtitleLarge style={styles.whiteText}>1. INTRODUCTION</SubtitleLarge>
                    </View>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                This EasyXDI report provides you with insights into the risks to,
                                and resilience of, the type of asset you selected, at the location
                                you placed it, under the extreme weather and climate change
                                scenarios applied at www.EasyXDI.com.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                The results are based on computationally testing the material and
                                design specifications of your selected asset type and its component
                                elements against a range of extreme weather impacts such as
                                flooding, coastal inundation, heat, forest fire, subsidence and
                                extreme wind. This analysis utilises a combination of public and
                                commercial data.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                This analysis is powered by proprietary Climate Risk Engines that
                                combine engineering data and local hazard maps with a statistical
                                interrogation of historical weather and climate model projections to
                                provide a financial analysis of future risk.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                The results are a Technical Insurance Premium (TIP) expressed as a
                                percentage of the replacement value of asset – referred to as the
                                Percentage of Value At Risk (VAR%). This is effectively the forward
                                looking annualised average cost of damage that would be caused by
                                climate change and extreme weather.
                            </Paragraph>
                            <Paragraph>(See Analysis Results for further detail).</Paragraph>
                        </View>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Right]}>
                            <Br1></Br1>
                            <Paragraph>
                                It’s important to note that these results apply to a Representative
                                Asset based on a selected type (called an Archetype),
                                pre-constructed in EasyXDI, against which a range of future climate
                                scenarios are tested.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                You may have chosen to customise some of the features of this
                                Representative Asset in the settings tab on EasyXDI.com. These will
                                be displayed in the ‘Analysis Settings’ section of this report as
                                ‘customised’ or ‘default’. EasyXDI Archetypes include assumptions
                                about design specifications and materials used in major elements
                                including electrical, civil, mechanical and engineering components.
                                This helps provide insights into an asset’s vulnerability to
                                different hazards.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                Please note, though these results can be useful, they cannot be
                                taken as indicating actual future risks as they are not testing a
                                real asset, only a Representative Asset. This analysis uses
                                scenarios which are only one possible view of the future and models
                                which are themselves a representation of the climate change.
                                Specific emissions scenarios and climate models used are listed
                                below in Analysis Settings.
                            </Paragraph>
                        </View>
                    </View>
                    <RedBorderSection>
                        <Paragraph>
                            This report is based on a standard Representative Asset. Deeper insights
                            are possible using more detailed asset-specific data, where available.
                            Other XDI products and services including the testing of adaptation
                            options and cost benefit analysis are available. If this report
                            identifies significant risk that you wish to investigate further please
                            contact us at: info@xdi.systems.
                        </Paragraph>
                    </RedBorderSection>

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
            </React.Fragment>
        );
    }
}

export default Introduction;
