import React from "react"
import ReactLoading from "react-loading"

// Wrapper around ReactLoading animation for simplified rendering
/**
 *
 * @param {object} param0
 */
const Loading = ({ size, colour }) => {
    size = size || 50
    // Default Easy Red.
    colour = colour || "#c10000"

    return (
        <ReactLoading
            className="Loading"
            type="bars"
            color={colour}
            height={size / 2}
            width={size}
            z-index={100}
        />
    )
}
/**
 *
 * @param {object} param0
 */
const EasyEditProfileLoading = ({ size }) => {
    size = size || 50
    return (
        <ReactLoading
            className="CVEditProfileLoading login-loading"
            type="bars"
            color="#c10000"
            height={20}
            width={50}
            z-index={100}
        />
    )
}

/**
 * CV style Loading Animation
 *
 * @param {object} size - Sets size of Loading animation
 *
 */
const CVLoading = ({ size }) => {
    size = size || 50;
    return (
        <ReactLoading
            className="CVLoading login-loading"
            type="balls"
            color="#f26522"
            height={size / 2}
            width={size}
            z-index={100}
        />
    );
};

// /**
//  * CV style Loading Animation for forms
//  * @returns - Loading animation
//  */
// const CVFormLoading = ({ size }) => {
//     return <div className="CVForm" height="100%" width="100%" z-index={10000} >
//         <ReactLoading
//             className="loaderCVForm"
//             type="balls"
//             color="#f26522"
//             height="100vh"
//             width="100px"
//             style={{alignContent:"center"}}
//             display ="inlineFlex"/>;
//     </div>
// }

const CVEditProfileLoading = ({ size }) => {
    size = size || 50;
    return (
        <ReactLoading
            className="CVEditProfileLoading login-loading"
            type="balls"
            color="#f26522"
            height={20}
            width={50}
            z-index={100}
        />
    );
};

export default Loading
export { EasyEditProfileLoading, CVEditProfileLoading, CVLoading }
