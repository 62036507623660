import _ from "lodash";

const fetchImageFromHighChartRemote = async (dataString, width = 1000) => {
    try {
        const exportUrl = "https://export.highcharts.com/";

        const response = await jQuery.ajax({
            method: "POST",
            url: exportUrl,
            cache: false,
            contentType: "application/json",
            data: JSON.stringify({
                infile: dataString,
                async: false,
                constr: "Chart",
                scale: false,
                type: "image/png",
                width,
            }),
            xhr: function () {
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                return xhr;
            }
        });
        console.log("fetchImageFromHighChartRemote Success", response);
        if (response) {
            return (window.URL || window.webkitURL).createObjectURL(response);
        } else {
            throw "An unknown error occurred.";
        }
    } catch (e) {
        console.log("Error loading Image from highcharts Remote:", e);
    }
};

export { fetchImageFromHighChartRemote };
