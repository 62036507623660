import React from "react"
import classNames from "classnames"
import Select from "react-select"

import "./NiceSelect.scss"

export default class NiceSelect extends React.Component {
    render() {
        const { options, multiple, onChange, value, className, disabled, ...rest } = this.props

        let optionsFlat = options
        if (options && options.length > 0 && options[0].options) {
            optionsFlat = [].concat(...options.map((opt) => opt.options))
        }

        let selectValue = null
        if (typeof value != "undefined") {
            if (multiple) {
                selectValue = optionsFlat.filter((opt) => value.includes(opt.value))
            } else {
                selectValue = optionsFlat.find((opt) => value == opt.value)
            }
        }

        return (
            <Select
                {...rest}
                className={classNames(className, "NiceSelect", "react-select")}
                classNamePrefix="react-select"
                isMulti={multiple}
                options={options}
                value={selectValue}
                onChange={(selected) =>
                    onChange(multiple ? selected.map((opt) => opt.value) : selected.value)
                }
                isDisabled={disabled}
            />
        )
    }
}
