import { observable } from "mobx"
import {checkProp} from '../utils.js'

class AdaptationState {
    // Options for design-related adaptation.
    // object keyed by analysis ID/pathway/option/design values
    @observable adaptationDesignValues = {}

    // Options for element material-related adaptation.
    // object keyed by analysis ID/pathway/option/element
    @observable adaptationMaterialValues = {}

    // Adapted analysis results
    // object keyed by ID, pathway, option, containing analyses
    @observable adaptation = {}

    // Values entered into customisation fields for each option
    @observable adaptationValues = {}

    // These should be fairly obvious
    @observable currentOption = null
    @observable currentPathway = null
    @observable currentAnalysisId = null

    // TODO: Not sure what these do, they don't appear to be used in Globe?
    @observable assetValues = {}

    // TODO: This should probably be moved back into Easy?
    @observable savedAdaptations = {}

    // This is used to store new data when adaptations are run
    path = {
        1: {},
        2: {},
        3: {},
    }

    year = {
        pathway1: {
            option1: 2030,
            option2: 2030,
            option3: 2030,
        },
        pathway2: {
            option1: 2030,
            option2: 2030,
            option3: 2030,
        },
        pathway3: {
            option1: 2030,
            option2: 2030,
            option3: 2030,
        },
    }

    // TODO: There are a bunch of other things added to this class that should
    // either be documented here, or removed from this object.
    // TODO: should this class produce an immutable object?

    reset = () => {
        this.adaptationDesignValues = {}
        this.adaptationMaterialValues = {}
        this.adaptation = {}
        this.adaptationValues = {}
        this.currentOption = null
        this.currentPathway = null
        this.currentAnalysisId = null
        this.assetValues = {}
        this.savedAdaptations = {}
        this.year = {
            pathway1: {
                option1: 2030,
                option2: 2030,
                option3: 2030,
            },
            pathway2: {
                option1: 2030,
                option2: 2030,
                option3: 2030,
            },
            pathway3: {
                option1: 2030,
                option2: 2030,
                option3: 2030,
            },
        }

        this.path = {
            1: {},
            2: {},
            3: {},
        }

        // TODO: These need to be cleaned up elsewhere, or added to the AdaptationState properly.
        delete this.pathwayJustUpdated
        delete this.npv
        delete this.discountRate

        // TODO: These too
        for (let p of [1, 2, 3]) {
            // if (checkProp(this, `pathway${p}NewData`)) {
            //     delete this[`pathway${p}NewData`]
            // }
            // if (checkProp(this, `pathway${p}NewRiskHazards`)) {
            //     delete this[`pathway${p}NewRiskHazards`]
            // }

            for (let o of [1, 2, 3]) {
                if (checkProp(this, `initialP${p}O${o}_onLoad`)) {
                    delete this[`initialP${p}O${o}_onLoad`]
                }
            }
        }
    }
}

const adaptState = new AdaptationState()

export default adaptState
