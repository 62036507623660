/**
 * This file contains styles for PDF report
 */
import { StyleSheet } from "@react-pdf/renderer"
import styled from "@react-pdf/styled-components"

const styles = StyleSheet.create({
    section: { color: "#ffffff", textAlign: "center" },

    cover: { width: "100%", height: "100%" },
    customPages: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    fullHeight: {
        // height: "auto",
        // backgroundColor: "pink"
    },
    MultiCol: {
        flex: "auto",
        flexDirection: "row",
        //flexBasis: "content",
        alignItems: "stretch",
        justifyContent: "stretch",
        backgroundColor: "#ffffff",
    },
    ColumnLayout2: {
        //flex:1,
        //flexDirection: "column",
        width: "50%",
        //backgroundColor: "yellow",

        // padding: 0,
        // margin: 0
    },

    ColumnLayout2Left: {
        paddingRight: "10px",
    },
    ColumnLayout2Right: {
        //paddingLeft: "10px",
    },
    containerWithRedBottomBar: {
        flexDirection: "row",
        borderBottomWidth: 2,
        borderBottomColor: "#b51218",
        borderBottomStyle: "solid",
        alignItems: "stretch",
    },
    outer: { flex: 1 },
    headerContainer: {
        flexDirection: "row",
    },
    leftColumn: {
        //flex: 1,
        flexGrow: 6,
        //height:"50px",
        //order: 2,
        //width:'20px',
        flexDirection: "column",
    },
    rightColumn: {
        //flex: 1,
        flexGrow: 2,
        //height:"50px",
        //order: 3,
        //width:'20px',
        flexDirection: "column",
        alignItems: "flex-end",
        justifySelf: "flex-end",
    },

    redText: { color: "#b51218" },
    whiteText: { color: "#ffffff" },
    IndexPage: {},
    redBackground: { backgroundColor: "#b51218" },
    darkGreyBackground: { backgroundColor: "#414243", paddingHorizontal: 10 },
    lightGreyBackground: { backgroundColor: "#8d8f92", paddingHorizontal: 3 },
    lightGreyCell: {
        backgroundColor: "#e6e7e9",
        paddingHorizontal: 2,
        paddingVertical: 3,
    },
    lightGreyCellOuter: { margin: 1 },
    whiteCell: { paddingHorizontal: 0, paddingVertical: 3 },
    whiteCellOuter: { margin: 1 },
    textCenter: { textAlign: "center" },
    IndexChildItem: {
        width: 35,
        //order: 1,//flex: 1,
        //flexDirection: "column",
        //height:"50px",
        //flexGrow: 0
        //width:'20px',
    },
    cell: {
        margin: 1,
    },
    cellInnerItem: {
        wordWrap: "break-word",
        whiteSpace: "nowrap",
        textOverflow: "hidden",

        overflow: "hidden",
    },
    headingCell: {
        width: "39%",
    },
    valueCell: {
        width: "30%",
    },
    circle: {
        borderTopLeftRadius: "75",
        borderTopRightRadius: "75",
        borderBottomRightRadius: "75",
        borderBottomLeftRadius: "75",
        backgroundColor: "#414243",
        height: 150,
        width: 150,
        margin: "auto",
    },
    arrowHead: {
        color: "#414243",
        fontSize: 50,
        textAlign: "center",
    },
    circleHeading: {
        color: "#ffffff",
        fontSize: 50,
        textAlign: "center",
        paddingVertical: 20,
        paddingHorizontal: 20,
    },
    circleValue: {
        color: "#ffffff",
        fontSize: 20,
        textAlign: "center",
    },
    smallCircle: {
        borderTopLeftRadius: "75",
        borderTopRightRadius: "75",
        borderBottomRightRadius: "75",
        borderBottomLeftRadius: "75",
        backgroundColor: "#414243",
        height: 25,
        width: 25,
        margin: "auto",
    },
    smallCircleLable: {
        color: "#ffffff",
        fontSize: 14,
        textAlign: "center",
        marginVertical: "auto",
    },
    cirleRightText: {
        //paddingTop: 3
    },
    darkheading: {
        fontSize: 12,
        fontFamily: "Roboto Condensed Bold",
    },
    pieChartImage: {
        height: "auto",
    },
    hazardImage: {
        height: "auto", //260
    },
    vulnerabilityImage: {
        height: "auto", //300
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
        fontFamily: "Roboto Condensed",
    },
})

const Subtitle = styled.Text`
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    color: #b51218;
    font-family: "Roboto Condensed Bold";
    font-weight: 700;
`

const SubtitleLarge = styled.Text`
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 18px;
    font-family: "Roboto Condensed Bold";
    font-weight: 700;
`

const SubtitleBlack = styled.Text`
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    color: black;
    font-family: "Roboto Condensed Bold";
    font-weight: 700;
`
const Paragraph = styled.Text`
    font-size: 12px;
    lineheight: 20px;
    text-align: left;
    font-family: "Roboto Condensed";
    letter-spacing: 0.5px;
`

const Bold = styled.Text`
    font-size: 12px;
    lineheight: 20px;
    text-align: left;
    font-family: "Roboto Condensed Bold";
    letter-spacing: 0.5px;
    font-weight: 700;
`

const Br1 = styled.View`
    height: 10;
`

const LightGreyCell = styled.View`
    height: 10;
`

const SolidRedBox = styled.View`
    background-color: #b51218;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: #ffffff;
`

const ThinRedBox = styled.View`
    border-style: solid;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-color: #b51218;
    border-right-color: #b51218;
    border-left-color: #b51218;
    border-bottom-color: #b51218;
`
export {
    Subtitle,
    SubtitleLarge,
    SubtitleBlack,
    Paragraph,
    styles,
    Br1,
    LightGreyCell,
    SolidRedBox,
    ThinRedBox,
    Bold,
}
