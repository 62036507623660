import React from "react"
import autoBind from "react-autobind"
import styled from "@react-pdf/styled-components"
import { Text, View, Page, Image } from "@react-pdf/renderer"
import PropTypes from "prop-types"

import {
    Subtitle,
    SubtitleLarge,
    Paragraph,
    styles,
    Br1,
    Bold,
} from "../PdfReportComponents/Shared.jsx"

const currencyFormatter = new Intl.NumberFormat();

class AnalysisSettings extends React.Component {
    static propTypes = {
        analysis: PropTypes.object.isRequired,
        fieldDefaultValues: PropTypes.object.isRequired,
        locationImage: PropTypes.string,
        satelliteViewImage: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autoBind(this)
    }
    render() {
        const { analysis, locationImage, satelliteViewImage, fieldDefaultValues } = this.props
        const ImageWrapper = styled.View`
            border-style: solid;
            border-top-width: 1px;
            border-right-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-top-color: #b51218;
            border-right-color: #b51218;
            border-left-color: #b51218;
            border-bottom-color: #b51218;
            height: 250px;
            margin-left: auto;
            margin-right: auto;
        `
        const ColumnHead = styled.View`
            height: 20;
            margin-top: 20;
        `

        const BlockCell = styled.View`
            height: 20;
            margin-top: 20;
        `

        const hasReplacementCost = !!fieldDefaultValues.replacementCost &&
            !!analysis.inputs.asset.properties.replacementCost;

        return (
            <React.Fragment>
                <Page size="A4" style={styles.customPages}>
                    <View
                        style={[
                            styles.redBackground,
                            { paddingVertical: 10, paddingHorizontal: 10, marginBottom: 10 },
                        ]}>
                        <SubtitleLarge style={styles.whiteText}>2. ANALYSIS SETTINGS</SubtitleLarge>
                    </View>

                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>
                            {" "}
                            2.1 YOUR ASSET DETAILS
                        </SubtitleLarge>
                    </View>

                    <View style={styles.MultiCol}>
                        <View
                            style={[styles.ColumnLayout2, styles.ColumnLayout2Left, { width: 70 }]}>
                            <Br1></Br1>
                            <Paragraph>Address:</Paragraph>
                            <Br1></Br1>
                            <Paragraph>Lat long:</Paragraph>
                            <Br1></Br1>
                            <Paragraph>Elevation:</Paragraph>
                        </View>
                        <View
                            style={[
                                styles.ColumnLayout2,
                                styles.ColumnLayout2Left,
                                { width: "70%" },
                            ]}>
                            <Br1></Br1>
                            <Paragraph>{analysis.properties.address}</Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                {fieldDefaultValues.latitude.toFixed(5)}N,
                                {fieldDefaultValues.longitude.toFixed(5)}E
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>{fieldDefaultValues.elevation.toFixed(1)} (m)</Paragraph>
                        </View>
                    </View>

                    <Br1></Br1>

                    <ImageWrapper>
                        <Image style={[{ height: "100%", width: "auto" }]} src={locationImage} />
                    </ImageWrapper>
                    <Br1></Br1>
                    <ImageWrapper>
                        <Image
                            style={[{ height: "100%", width: "auto" }]}
                            src={satelliteViewImage}
                        />
                    </ImageWrapper>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>
                            2.2 YOUR ARCHETYPE DETAILS
                        </SubtitleLarge>
                    </View>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                EasyXDI uses predefined archetypes to represent the materials and
                                design specifications of an asset. Archetypes assume things such as
                                construction materials, wind speed thresholds, floor height above
                                ground and build year to inform the asset’s vulnerability when
                                exposed to a hazard.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                It is possible to change many of attributes of the archetype using
                                the ‘Customise’ tab on EasyXDI website.
                            </Paragraph>
                        </View>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Right]}>
                            <Br1></Br1>
                            <Paragraph>
                                If you have already done this, it will be displayed below as
                                ‘customised’ or ‘default’ (if you have made no changes).
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                It’s important to note that the archetype specifications define a
                                representative asset and cannot therefore be taken as the
                                specifications of your actual asset.
                            </Paragraph>
                        </View>
                    </View>

                    <Br1></Br1>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Your Archetype</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        The asset archetype you have chosen is: {analysis.properties.archetype}
                    </Paragraph>
                    <Br1></Br1>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Design Specifications</Subtitle>
                    </View>

                    <Br1></Br1>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "40%" }]}>
                            <ColumnHead></ColumnHead>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "30%" }]}>
                            <ColumnHead>
                                <Paragraph style={{ fontWeight: "bold" }}>
                                    DEFAULT SETTINGS
                                </Paragraph>
                            </ColumnHead>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "30%" }]}>
                            <ColumnHead>
                                <Paragraph style={{ fontWeight: "bold" }}>
                                    CURRENT SETTINGS
                                </Paragraph>
                            </ColumnHead>
                        </View>
                    </View>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "40%" }]}>
                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Build year
                                </Paragraph>
                            </View>
                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Heat threshold (c)
                                </Paragraph>
                            </View>

                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Wind speed design threshold
                                </Paragraph>
                            </View>

                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Forest fire protection
                                </Paragraph>
                            </View>

                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Foundation design
                                </Paragraph>
                            </View>

                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Asset lifetime
                                </Paragraph>
                            </View>

                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Floor height above ground (metres)
                                </Paragraph>
                            </View>

                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Elevation above sea level (metres)
                                </Paragraph>
                            </View>
                            <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Elevation dataset
                                </Paragraph>
                            </View>
                            {hasReplacementCost && <View style={styles.whiteCellOuter}>
                                <Paragraph
                                    style={[
                                        styles.whiteCell,
                                        { textAlign: "right", paddingRight: 5 },
                                    ]}>
                                    Replacement cost
                                </Paragraph>
                            </View>}
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "30%" }]}>
                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.buildYear}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.heatThreshold}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    1 in {Math.round(1 / fieldDefaultValues.windThreshold)}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.fireProtection}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.foundationDesign}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.lifetime}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.heightAboveGround}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {fieldDefaultValues.elevation.toFixed(1)}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {"3rd Party Source"}
                                </Paragraph>
                            </View>

                            {hasReplacementCost && <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {currencyFormatter.format(fieldDefaultValues.replacementCost)}
                                </Paragraph>
                            </View>}
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "30%" }]}>
                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.buildYear}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.metadata.heat.threshold_temperature ? analysis.metadata.heat.threshold_temperature : analysis.inputs.asset.properties.heatThreshold}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    1 in{" "}
                                    {Math.round(1 / analysis.inputs.asset.properties.windThreshold)}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.fireProtection}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.foundationDesign}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.lifetime}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.heightAboveGround}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.elevation.toFixed(1)}
                                </Paragraph>
                            </View>

                            <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {analysis.inputs.asset.properties.elevation.toFixed(1) ==
                                    fieldDefaultValues.elevation.toFixed(1)
                                        ? "elevationDataSet" in analysis.inputs.asset.properties
                                            ? analysis.inputs.asset.properties.elevationDataSet
                                            : "Unknown"
                                        : "User Configured"}
                                </Paragraph>
                            </View>

                            {hasReplacementCost && <View style={styles.lightGreyCellOuter}>
                                <Paragraph style={styles.lightGreyCell}>
                                    {currencyFormatter.format(analysis.inputs.asset.properties.replacementCost)}
                                </Paragraph>
                            </View>}
                        </View>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Elements and Materials</Subtitle>
                    </View>
                    <Br1></Br1>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "39%", margin: 1 }]}>
                            <View
                                style={[
                                    styles.redBackground,
                                    styles.whiteText,
                                    {
                                        height: 30,
                                        paddingTop: 7,
                                        paddingHorizontal: 2,
                                        fontWeight: "bold",
                                    },
                                ]}>
                                <Paragraph>ELEMENT</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "30%", margin: 1 }]}>
                            <View
                                style={[
                                    styles.redBackground,
                                    styles.whiteText,
                                    {
                                        height: 30,
                                        paddingTop: 7,
                                        paddingHorizontal: 2,
                                        fontWeight: "bold",
                                    },
                                ]}>
                                <Paragraph>DEFAULT SETTING</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "30%", margin: 1 }]}>
                            <View
                                style={[
                                    styles.redBackground,
                                    styles.whiteText,
                                    {
                                        height: 30,
                                        paddingTop: 7,
                                        paddingHorizontal: 2,
                                        fontWeight: "bold",
                                    },
                                ]}>
                                <Paragraph>CURRENT SETTING</Paragraph>
                            </View>
                        </View>
                    </View>

                    {Object.keys(analysis.inputs.asset.properties.elementMaterials).map((el) => (
                        <View style={styles.MultiCol} wrap={false} key={el}>
                            <View style={[styles.cell, styles.headingCell, styles.lightGreyCell]}>
                                <View style={[{ marginVertical: "auto" }]}>
                                    <Paragraph>
                                        {el.substring(0, 1) +
                                            el
                                                .substring(1)
                                                .replace(/([A-Z])/g, " $1")
                                                .toLowerCase()}
                                    </Paragraph>
                                </View>
                            </View>

                            <View style={[styles.cell, styles.valueCell, styles.lightGreyCell]}>
                                {/* TODO: This needs to be a default value! */}
                                <Paragraph>
                                    {fieldDefaultValues.elementMaterials[el].name}
                                </Paragraph>
                            </View>
                            <View style={[styles.cell, styles.valueCell, styles.lightGreyCell]}>
                                <Paragraph>
                                    {analysis.inputs.asset.properties.elementMaterials[el].name}
                                </Paragraph>
                            </View>
                        </View>
                    ))}

                    <View style={styles.MultiCol}></View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>2.3 CLIMATE SETTINGS</SubtitleLarge>
                    </View>
                    <Br1></Br1>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Climate and Hazard scenarios</Subtitle>
                    </View>

                    <Br1></Br1>
                    <Br1></Br1>
                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "25%", margin: 1 }]}>
                            <View
                                style={[
                                    styles.redBackground,
                                    styles.whiteText,
                                    {
                                        height: 30,
                                        paddingTop: 7,
                                        paddingHorizontal: 2,
                                        fontWeight: "bold",
                                    },
                                ]}>
                                <Paragraph>HAZARD</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "22%", margin: 1 }]}>
                            <View
                                style={[
                                    styles.redBackground,
                                    styles.whiteText,
                                    {
                                        height: 30,
                                        paddingTop: 7,
                                        paddingHorizontal: 2,
                                        fontWeight: "bold",
                                    },
                                ]}>
                                <Paragraph>DATASET</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "52%", margin: 1 }]}>
                            <View
                                style={[
                                    styles.redBackground,
                                    styles.whiteText,
                                    {
                                        height: 30,
                                        paddingTop: 7,
                                        paddingHorizontal: 2,
                                        fontWeight: "bold",
                                    },
                                ]}>
                                <Paragraph>DETAILS</Paragraph>
                            </View>
                        </View>
                    </View>

                    {Object.keys(analysis.metadata).map((haz) => (
                        <HazardMetadataRow haz={haz} metadata={analysis.metadata[haz]} key={haz} />
                    ))}

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Emissions Scenarios</Subtitle>
                    </View>
                    <Br1></Br1>
                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                The Climate Risk Engines stress test assets using emissions
                                scenarios modelled by international climate research groups based on
                                the Representative Concentration Pathways published by the
                                Intergovernmental Panel for Climate Change (IPCC). The scenario used
                                in this analysis, RCP 8.5, provides a high greenhouse gas emissions
                                trajectory which is most consistent with current global emissions
                                behaviour, trends and the business-as-usual outlook.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                Sea Level Rise: This analysis uses a sea level rise projection is
                                based on Haigh 2014, which is 1.5m sea level rise above 1990 levels.
                                This sits between IPCC (2013) projections and those used by the U.S.
                                Government’s NOAA extreme sea level rise scenarios for 2100.
                            </Paragraph>
                        </View>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                Climate Parameters: The projections for climate parameters covering
                                temperatures, precipitation, forest fire and wind are sourced from
                                the results of a range of General Circulation Models (GCM) and
                                Regional Concentration Models (RCM) developed by international
                                research agencies and published as part of the Coordinated Regional
                                Climate Downscaling Experiment (CORDEX).
                            </Paragraph>

                            <Br1></Br1>
                            <Paragraph>
                                Bias Correction: Historical weather data may be used to adjust
                                climate model data to align with known local weather behaviour and
                                statistics.
                            </Paragraph>
                        </View>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
            </React.Fragment>
        )
    }
}

export default AnalysisSettings

// TODO: We should use this in many other places too.. it's safer.
// see https://eslint.org/docs/rules/no-prototype-builtins
let checkProp = (obj, prop) => {
    return Object.prototype.hasOwnProperty.call(obj, prop)
}

let formatHazardName = (haz_name) => {
    var haz_name_components = haz_name.match(/([^_]+)/g) || [haz_name]
    haz_name_components.forEach(function (haz_name_component, i) {
        haz_name_components[i] = haz_name_component[0].toUpperCase() + haz_name_component.slice(1)
    })
    return haz_name_components.join(" ")
}

// Individual hazard metadata row
class HazardMetadataRow extends React.Component {
    static propTypes = {
        haz: PropTypes.object,
        metadata: PropTypes.object,
    }

    constructor(props) {
        super(props)
        autoBind(this)
    }

    // Quick function to format the sub-rows for projection/historical/context data.
    sub_row(section, section_name, list_data) {
        return (
            <React.Fragment>
                <View
                    style={[
                        {
                            width: "100%",
                            flex: "auto",
                            flexDirection: "row",
                            alignItems: "stretch",
                        },
                    ]}>
                    <View
                        style={[
                            { width: "30%", marginVertical: "auto" },
                            styles.cell,
                            styles.lightGreyCell,
                        ]}>
                        <Paragraph style={[{ marginVertical: "auto" }]}>{section_name}</Paragraph>
                    </View>
                    <View style={[{ width: "70%" }, styles.cell, styles.lightGreyCell]}>
                        {Object.keys(list_data).map((key) => (
                            <View key={key}>
                                <Bold>{key}:</Bold>
                                <Paragraph>{list_data[key]}</Paragraph>
                            </View>
                        ))}
                    </View>
                </View>
            </React.Fragment>
        )
    }

    render() {
        const { haz, metadata } = this.props

        let haz_name = checkProp(metadata, "description") ? metadata.description : haz

        if (checkProp(metadata, "no_calc_reason")) {
            return (
                <View style={styles.MultiCol}>
                    <View
                        style={[
                            styles.cell,
                            styles.headingCell,
                            styles.lightGreyCell,
                            { width: "25%", marginLeft: 0 },
                        ]}>
                        <View style={[{ marginVertical: "auto" }]}>
                            <Paragraph>{formatHazardName(haz_name)}</Paragraph>
                        </View>
                    </View>
                    <View style={{ width: "75%" }}>
                        {this.sub_row("failure", "Not Calculated", {
                            Reason: metadata["no_calc_reason"],
                        })}
                    </View>
                </View>
            )
        }

        let details = {}
        if (checkProp(metadata, "projection")) {
            let mdata = metadata.projection
            details["projection"] = {}
            if (checkProp(mdata, "description")) {
                details["projection"]["Description"] = mdata.description
            }
            if (checkProp(mdata, "source")) {
                details["projection"]["Source"] = mdata.source
            }

            if (checkProp(metadata.projection, "metadata")) {
                mdata = metadata.projection.metadata
                if (checkProp(mdata, "GCM")) {
                    details["projection"]["GCM"] = mdata.GCM + " " + mdata.ensemble_member
                    details["projection"]["RCM"] = mdata.RCM + " " + mdata.RCM_version
                }
                if (checkProp(mdata, "dataset")) {
                    details["projection"]["Dataset"] = mdata.dataset
                }
                if (checkProp(mdata, "domain")) {
                    details["projection"]["Domain"] = mdata.domain
                }
            }
        }

        if (checkProp(metadata, "historical")) {
            let mdata = metadata.historical
            details["historical"] = {}
            if (checkProp(mdata, "description")) {
                details["historical"]["Description"] = mdata.description
            }
            if (checkProp(mdata, "source")) {
                details["historical"]["Source"] = mdata.source
            }
        }

        if (checkProp(metadata, "context")) {
            details["context"] = {}
            // Context is a dictionary keyed by context type, with a sub-dictionary keyed by layer name.
            // We might want to add more detail here at some point
            _.forOwn(metadata.context, (cmeta_list) => {
                _.forOwn(cmeta_list, (mdata) => {
                    details.context[mdata.description] = mdata.name
                })
            })
        }

        if (Object.keys(details).length === 0) {
            console.log(`Bad metadata for ${haz}`)
            return (
                <View style={styles.MultiCol}>
                    <View
                        style={[
                            styles.cell,
                            styles.headingCell,
                            styles.lightGreyCell,
                            { width: "25%", marginLeft: 0 },
                        ]}>
                        <View style={[{ marginVertical: "auto" }]}>
                            <Paragraph>{formatHazardName(haz_name)}</Paragraph>
                        </View>
                    </View>
                    <View style={{ width: "75%" }}>
                        <View
                            style={[
                                {
                                    width: "100%",
                                    flex: "auto",
                                    flexDirection: "row",
                                    alignItems: "stretch",
                                },
                            ]}>
                            <View style={[{ width: "30%" }, styles.cell, styles.lightGreyCell]}>
                                <Paragraph>Metadata missing</Paragraph>
                            </View>
                            <View style={[{ width: "70%" }, styles.cell, styles.lightGreyCell]}>
                                <Paragraph>Re-run analysis to update metadata</Paragraph>
                            </View>
                        </View>
                    </View>
                </View>
            )
        }

        return (
            <React.Fragment>
                <View style={styles.MultiCol} wrap={false}>
                    <View
                        style={[
                            styles.cell,
                            styles.headingCell,
                            styles.lightGreyCell,
                            { width: "25%", marginLeft: 0 },
                        ]}>
                        <View style={[{ marginVertical: "auto" }]}>
                            <Paragraph>{formatHazardName(haz_name)}</Paragraph>
                        </View>
                    </View>
                    <View style={{ width: "75%" }}>
                        {details.projection &&
                            this.sub_row("projection", "Projection", details.projection)}
                        {details.historical &&
                            this.sub_row("historical", "Bias correction", details.historical)}
                        {details.context &&
                            this.sub_row("context", "Context data", details.context)}
                    </View>
                </View>
            </React.Fragment>
        )
    }
}
