import React from "react";
import autoBind from "react-autobind";
import { Link, Text, View, StyleSheet, Page, Image } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";

import {
    SubtitleLarge,
    Paragraph,
    Br1,
    ThinRedBox,
    styles as stylesShared,
} from "../PdfReportComponents/Shared.jsx";

class Conclusion extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        const styles = StyleSheet.create({
            logoLeft: {
                position: "absolute",
                left: 10,
                bottom: 35,
                height: 48,
            },
            logoRight: {
                position: "absolute",
                right: 20,
                bottom: 40,
                height: 15,
            },
            coverpage: { backgroundColor: "white" },
            footerLinks: {
                fontSize: 12,
                textDecoration: "none",
                fontFamily: "Roboto Condensed",
                position: "absolute",
                bottom: 15,
                textDecoration: "none",
                color: "#000000",
            },
            footerLinkLeft: {
                left: 12,
            },
            footerLinkRight: {
                right: 20,
            },

            footerImage: {
                position: "absolute",
                bottom: 100,
                left: 0,
                right: 0,
                width: "105vw",
                height: 100,
            },
            redText: { color: "#b51218" },
        });
        return (
            <Page size="A4" style={stylesShared.customPages}>
                <View
                    style={[
                        stylesShared.redBackground,
                        { paddingVertical: 10, paddingHorizontal: 10, marginBottom: 10 },
                    ]}
                >
                    <SubtitleLarge style={stylesShared.whiteText}>5. CONCLUSION</SubtitleLarge>
                </View>

                <Br1></Br1>
                <Paragraph>
                    This EasyXDI report has provided an XDI Risk Rating and a calculation of
                    Percentage Value At Risk based on your choice of a Representative Asset, the
                    location selected and the future climate change scenario used. Though insurance
                    is a private contractual arrangement, these results can help understand whether
                    such an asset might have problems accessing affordable insurance now or in the
                    future.
                </Paragraph>

                <Br1></Br1>
                <Paragraph>
                    You may wish to undertake a new analysis with changed settings that consider
                    improved resilience - for example by changing design specifications or the
                    choice of materials used in the Representative Asset.
                </Paragraph>

                <Br1></Br1>
                <Paragraph>
                    If you wish to undertake detailed diagnostic analysis, plan adaptation actions,
                    explore the impacts of hazards in more detail or assess large numbers of assets,
                    please contact our team at
                </Paragraph>
                <Link
                    style={{ textDecoration: "underline", color: "black", fontSize: 12 }}
                    src="info@xdi.systems"
                >
                    info@xdi.systems
                </Link>

                <Br1></Br1>
                <Br1></Br1>
                <Br1></Br1>
                <Br1></Br1>
                <Br1></Br1>

                <ThinRedBox style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Paragraph style={styles.redText}>CONTACT XDI</Paragraph>
                    <Br1></Br1>
                    <Paragraph>
                        Since 2006, organisations have turned to our team to help them analyse the
                        physical impacts of climate change to their business operations. XDI’s
                        analysis is underpinned by the Climate Risk Engines, developed through
                        extensive commercial engagement with industry and government
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        If this report identifies significant risk, XDI can provide more detailed
                        analysis through other XDI products and services. Please contact us for more
                        information: info@xdi.systems
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        All XDI products are powered by Climate Risk Engines. To find out more see
                        www.climateriskengines.com
                    </Paragraph>
                </ThinRedBox>

                <Image style={styles.footerImage} src="/static/images/report/solid.png" />

                <Image style={styles.logoLeft} src="/static/images/report/East_XDI_logos.png" />
                <Image style={styles.logoRight} src="/static/images/report/CRE_logo.png" />

                <Link
                    style={[styles.footerLinks, styles.footerLinkLeft]}
                    src="http://www.xdi.systems/"
                >
                    www.xdi.systems
                </Link>
                <Link
                    style={[styles.footerLinks, styles.footerLinkRight]}
                    src="http://www.climateriskengines.com/"
                >
                    www.climateriskengines.com
                </Link>
            </Page>
        );
    }
}

export default Conclusion;
