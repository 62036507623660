import React from "react";
import autoBind from "react-autobind";
import { Text, View, Page } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import {
    SubtitleBlack,
    styles,
} from "../PdfReportComponents/Shared.jsx";
class TableOfContents extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        const IndexText = styled.Text`
            margin-bottom: 5px;
            font-size: 12px;
            color: black;
            font-family: "Roboto Condensed";
        `;

        const tableOfContents = [
            { title: "INTRODUCTION", page: 6 },
            {
                title: "ANALYSIS SETTINGS", page: 7,
                items: [
                    { title: "Your Asset Details", page: 7 },
                    { title: "Your Archetype Details", page: 8 },
                ]
            },
            {
                title: "ANALYSIS RESULTS", page: 14,
                items: [
                    { title: "Introduction", page: 14 },
                    { title: "XDI Asset Rating", page: 15 },
                    { title: "Percentage of Value at Risk (VAR%) : All Hazards", page: 16 },
                    { title: "Failure Probability: All Hazards", page: 20 },
                    { title: "Vulnerability Diagnostics", page: 24 },
                    {
                        title: "Hazards Breakdown: VAR% and Failure Probability", page: 27,
                        items: [
                            { title: "Flood (Riverine)", page: 27 },
                            { title: "Coastal Inundation", page: 28 },
                            { title: "Forest Fire", page: 29 },
                            { title: "Soil Movement", page: 30 },
                            { title: "Extreme Wind", page: 31 },
                            { title: "Extreme Heat", page: 32 },
                            { title: "Freeze-Thaw", page: 33 },
                            { title: "Flood (Surface Water)", page: 34 },
                            { title: "Tropical Cyclone Wind", page: 35 },
                        ]
                    },
                ]
            },
            {
                title: "METHODS USED FOR ANALYSIS", page: 36,
                items: [
                    { title: "Stress-Test Approach", page: 36 },
                    { title: "The Climate Risk Engines", page: 36 },
                    { title: "A Representative Asset Archetype", page: 37 },
                    { title: "Included Hazards", page: 37 },
                    { title: "Mathematical Analysis", page: 37 },
                    { title: "Hazard Exposure", page: 37 },
                    { title: "Vulnerability Analysis; Damage and Failure Thresholds", page: 37 },
                    { title: "Weather Data", page: 38 },
                    { title: "Climate Change Modelling", page: 38 },
                    { title: "Scenario Run Data", page: 38 },
                ]
            }
        ]

        const TableOfContentItem = ({ title, page, header = false, items, style = null }) => {
            const headerStyle = header ? [styles.redText] : { fontWeight: 700 }

            return (
                <View style={style}>
                    <View style={styles.headerContainer}>
                        <View style={[styles.leftColumn]}>
                            <IndexText style={headerStyle}>{title}</IndexText>
                        </View>
                        <View style={styles.rightColumn}>
                            <IndexText style={headerStyle}>{page}</IndexText>
                        </View>
                    </View>
                    {items && items.length > 0 && items.map((i, index) => (
                        <TableOfContentItem
                            key={index}
                            title={i.title}
                            page={i.page}
                            items={i.items}
                            style={{ paddingLeft: "36px" }}
                        />
                    ))}
                </View>
            )
        }

        return (
            <React.Fragment>
                <Page size="A4" style={styles.customPages}>
                    <View style={[styles.containerWithRedBottomBar, { marginBottom: 35 }]}>
                        <SubtitleBlack>TABLE OF CONTENTS</SubtitleBlack>
                    </View>
                    <View style={{ flex: 1, margin: 0, padding: 0 }}>
                        {tableOfContents.map((header, index) => (
                            <TableOfContentItem
                                key={index}
                                title={`${index + 1}. ${header.title}`}
                                page={header.page}
                                items={header.items}
                                header
                            />
                        ))}
                    </View>

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
            </React.Fragment>
        );
    }
}

export default TableOfContents;
