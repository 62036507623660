import React from "react";
import { ProgressBar } from "react-bootstrap";
import autoBind from "react-autobind";

import "./ProcessingModal.scss";

const labels = ["Preparing report..."];

class ReportProcessingModal extends React.Component {
    _isMounted = false;
    _debugReportsTextOnly = crConstants.debugReportsTextOnly;

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            hideProgressBar: false,
        };
        autoBind(this);
    }

    incrementProgress() {
        // self.timeoutHandle = setTimeout(
        //     () => {
        //         if (this._isMounted === true) {
        //             this.setState({ progress: this.state.progress + 5 });
        //             if (this.state.progress < 100) this.incrementProgress();
        //         }
        //     },
        //     this._debugReportsTextOnly ? 100 + Math.random() * 100 : 10// speed of bar
        // );
    }

    hideProgressBar() {
        this.setState({ hideProgressBar: true });
    }

    componentDidMount() {
        this._isMounted = true;
        this.incrementProgress();
        window.scrollTo(0, 0)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const total = 20; //total 19 images, consider 20th as blob available
        let count = 0;
        if (nextProps.VARCharts !== null) count++;
        if (nextProps.pieChartsForAnnualVARStartYear !== null) count++;
        if (nextProps.pieChartsForAnnualVARMortgageTerm !== null) count++;
        if (nextProps.pieChartsForAnnualVAREndYear !== null) count++;
        if (nextProps.maxFailureProbabilityToDate !== null) count++;
        if (nextProps.annualFailureProbability !== null) count++;
        if (nextProps.pieChartsForAnnualFailureProbabilityStartYear !== null) count++;
        if (nextProps.pieChartsForAnnualFailureProbabilityMortgageTerm !== null) count++;
        if (nextProps.pieChartsForAnnualFailureProbabilityEndYear !== null) count++;
        if (nextProps.vulnerabilityStartYear !== null) count++;
        if (nextProps.vulnerabilityMortgageTerm !== null) count++;
        if (nextProps.vulnerabilityEndYear !== null) count++;
        if (nextProps.floodRiverine !== null) count++;
        if (nextProps.inundation !== null) count++;
        if (nextProps.forestFire !== null) count++;
        if (nextProps.soilSubsidence !== null) count++;
        if (nextProps.extremeWind !== null) count++;
        if (nextProps.extremeHeat !== null) count++;
        if (nextProps.freezeThaw !== null) count++;
        if (nextProps.floodSurfaceWater !== null) count++;
        if (nextProps.cycloneWind !== null) count++;

        const progress = (100 / total) * count;
        this.setState({ progress: progress });
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.timeoutHandle);
    }

    render() {

        const { progress, hideProgressBar } = this.state;

        const showProgress = true; //progress < 100 || !readyToRenderPrintView;
        const step = 0;

        return (
            <div>
                {!hideProgressBar && showProgress && (
                    <div>
                        <p className="progress-text">{labels[step]}</p>
                        <ProgressBar variant="success" now={progress} animated="true" />
                    </div>
                )}
            </div>
        );
    }
}

export default ReportProcessingModal;
