/**

/**
 * User Exception for re-usable nice error messages
 *
 * @param {string} message - short message to display.
 * @param {string} details - longer message to display after unhiding.
 */
function CRException(message, details) {
    this.message = message
    this.details = details
    this.name = "CRException"
    this.date = new Date()
}

/**
 * Throw a useful exception message that can be printed nicely
 *
 * @param {string} message - Main error message to display.
 * @param {object} data - Array of settings that were sent to the AJAX call.
 */
const throwCRException = (message, data) => {
    console.log(message)

    let details = ""
    if (data.settings && data.settings.url) {
        details += `Response from ${data.settings.url}:\n`
    }
    if (data.exception) {
        details += parseErrorResponse(data.exception)
    }
    console.log("details: ", details)

    logToLumberjack(message, data)

    throw new CRException(message, details)
}

/**
 * Logs an exception to Lumberjack
 *
 * @param {string} message - Main error message to display.
 * @param {object} data - Array of settings that were sent to the AJAX call.
 * @param {string} severity - Nominal error severity to log to lumberjack.
 * @param {string} type - type of exception (error, warning, etc).
 * @param {string} user - username.
 * @param {object} extra_data - any extra data that Lumberjack should store and display.
 */
async function logToLumberjack({ message, data, ...params }) {
    // These parameters can all be over-ridden in the function call (caught by `params`).
    // parameters beyond these wi
    let log_data = {
        app_name: `${window.location.hostname}`,
        time: new Date().toJSON(),
        user: null,
        severity: "error",
        type: "error",
        message: message,
        extra_data: data,
        ...params,
    }

    let log_settings = {
        method: "POST",
        contentType: "application/json",
        // eslint-disable-next-line no-undef
        url: `${crConstants.lumberjackHost}/api/v1/log`,
        data: JSON.stringify(log_data),
        dataType: "json",
    }
    try {
        let lumberjack_result = await jQuery.ajax(log_settings)
    } catch (ex) {
        console.log("Failed to send log data to lumberjack: ", {
            exception: ex,
            data: log_settings,
        })
    }
}

/**
 * Attempts to parse an error from the standard format of a ClimateRisk app
 *
 * @param {object} exception - Ajax exception caught.
 *
 * @returns {string} - response string from exceoption
 * */
const parseErrorResponse = (exception) => {
    // TODO: This is pretty convoluted. Do we need all of these?

    // JSON based responses
    let json = false
    if (exception.responseJSON) {
        json = exception.responseJSON
    } else if (exception.responseText) {
        try {
            json = JSON.parse(exception.responseText)
        } catch (e) {
            json = false
        }
    }

    if (json) {
        console.log("json", json)
        let message = ""
        if (json.error) {
            message += json.error.code ? `Error code: ${json.error.code}\n` : ""
            message += json.error.message ? `Error message:\n  ${json.error.message}\n` : ""
            return message
        }
        if (json.message) return `Error message:\n  ${json.message}\n\n`
    }

    if (exception.responseText) {
        // Check and see if the responseText contains valid HTML
        const doc = new DOMParser().parseFromString(exception.responseText, "text/html")
        if (doc.body.textContent) return doc.body.textContent

        // Otherwise use raw responseText
        return exception.responseText
    }
    // Check statusText
    // If you're getting this message, please check the console error for more details,
    // and consider adding more detail here.
    if (exception.statusText) return `statusText: ${exception.statusText}`

    // Otherwise return the exception as a string.
    return `Unknown exception (${exception}), possibly the server is unavailable?`
}

export { logToLumberjack, throwCRException, parseErrorResponse }
