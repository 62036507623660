/**
 * Analysis-reated getters (ana, elevation)
 */
import _ from "lodash"
import { throwCRException } from "./lumberjack"
import { checkProp, checkNested } from "./utils"
import googleElevation from "./google_elev.js"
import { getMaterials } from "./archetype.js"

/**
 * Analysis handler callback
 *
 * Wrapper for Ana that handles Analysis input preparation and output processing.
 *
 * @param inputs {object} - input dataset for Ana.
 * @param parameters {object} - Changes from the app UI that should be applied to the inputs
 * @param elements {object} - Archetype elements that should be changed from the app UI
 * @param extraMaterials {object} - Extra materials that are not supplied by acrobase
 */
const doAnalysis = async ({ inputs, parameters, elements, extraMaterials }) => {
    if (parameters) {
        // TODO: This is built to work with Globe. Easy might have a harder
        // time. Merge might be a PITA.

        // Height and elevation
        if (checkProp(parameters, "elevation")) {
            inputs.asset.properties["elevation"] = parameters.elevation
        }
        if (checkProp(parameters, "heightAboveGround")) {
            inputs.asset.properties["heightAboveGround"] = parameters.heightAboveGround
        }
        if (checkProp(parameters, "heightAboveGround") && checkProp(parameters, "elevation")) {
            inputs.asset.properties["totalHeight"] =
                parameters.heightAboveGround + parameters.elevation
        }

        // threshold properties
        if (checkProp(parameters, "thresholds")) {
            inputs.asset.properties["thresholds"] = parameters.thresholds
            // removes heatThreshold if thresholds are used
            if (parameters.thresholds.heat && inputs.asset.properties.heatThreshold) {
                delete inputs.asset.properties.heatThreshold
            }
            if (parameters.thresholds.wind && inputs.asset.properties.windThreshold) {
                delete inputs.asset.properties.windThreshold
            }
        }

        // Wind properties
        if (checkProp(parameters, "windThreshold")) {
            if (parameters.windThreshold == "NULL") {
                // This should only be possible if the windThreshold was
                // missing in the original asset spec, so we delete it if
                // necessary.
                if (checkProp(inputs.asset.properties, "windThreshold")) {
                    delete inputs.asset.properties.windThreshold
                }
            } else {
                // Or set a new value
                // TODO: Ana should probably return some indication of the
                // windspeed threshold it used for calculations.
                inputs.asset.properties["windThreshold"] = parameters.windThreshold
            }
        }
        if (checkProp(parameters, "buildYear")) {
            inputs.asset.properties["buildYear"] = parameters.buildYear
        }

        if (checkProp(parameters, "heatThreshold")) {
            inputs.asset.properties["heatThreshold"] = parameters.heatThreshold
        }

        // TODO: PARAMS with NO EFFECT
        // "archetype": "AWTP",
        // "lifetime": 50,
    }

    if (elements) {
        const arch_materials = getMaterials()
        const materials = {
            ...arch_materials,
            ...extraMaterials,
        }

        const hazards = inputs.scenario.hazards

        // Map selected materials into asset materialsDict
        // TODO: Move materials information into archetype.
        for (const ele of _.keys(inputs.asset.properties.materialsDict)) {
            // TODO: Update this with acrobase changes, remove this reduce.
            if (checkProp(elements, ele)) {
                const mat = elements[ele]
                const failure_coeffs = {}
                if (checkProp(materials, mat)) {
                    hazards.map((haz) => {
                        if (haz === "coastal_inundation" || haz === "inundation") {
                            if ("coastal_inundation" in materials[mat].failure_coefficients) {
                                // TODO: Fix in Ana, so that it accepts coastal_inundation
                                failure_coeffs["inundation"] =
                                    materials[mat].failure_coefficients["coastal_inundation"]
                            } else {
                                failure_coeffs["inundation"] =
                                    materials[mat].failure_coefficients["inundation"]
                            }
                        } else if (haz == "freeze_thaw" || haz == "freeze-thaw") {
                            if ("freeze_thaw" in materials[mat].failure_coefficients) {
                                // TODO: Fix in Ana, so that it accepts freeze_thaw
                                failure_coeffs["freeze-thaw"] =
                                    materials[mat].failure_coefficients["freeze_thaw"]
                            } else {
                                failure_coeffs["freeze-thaw"] =
                                    materials[mat].failure_coefficients["freeze-thaw"]
                            }
                        } else {
                            failure_coeffs[haz] = materials[mat].failure_coefficients[haz]
                        }
                    })
                } else {
                    // This shouldn't ever happen.
                    console.log("Material", mat, "not found for", ele, "setting to zero")
                    hazards.map((haz) => {
                        failure_coeffs[haz] = 0
                    })
                }
                inputs.asset.properties.materialsDict[ele] = failure_coeffs
            } else {
                // Deal with excluded elements separately
                console.log("Element", ele, "excluded, setting material coeffs to zero")
                inputs.asset.properties.materialsDict[ele] = hazards.reduce((out, haz) => {
                    out[haz] = 0
                    return out
                }, {})
            }
        }
    }

    // These parameters are design-level parameters that modify material
    // coefficients in order to reduce risk.
    // TODO: Delete this whole chunk, and move this logic to Ana, as
    // asset-level design parameters
    if (parameters) {
        if (checkProp(parameters, "fireProtection") && parameters.fireProtection != "none") {
            // haz = checkProp(inputs.scenario, "hazards")
            //     ? temp0.indexOf('soil_movement')
            //     : 3 ; // assume standard hazard order, with no missing hazards, forest_fire = 5

            let failure_multiplier = 1.0
            if (parameters.fireProtection == "heatEmber") {
                failure_multiplier = 0.5
            } else {
                // flame protected
                failure_multiplier = 0.25
            }

            for (let ele of Object.keys(inputs.asset.properties.materialsDict)) {
                // TODO: This won't work if the inputs are supplied by number, I think?
                inputs.asset.properties.materialsDict[ele].forest_fire *= failure_multiplier
            }
        }

        if (checkProp(parameters, "foundationDesign") && parameters.foundationDesign != "normal") {
            // haz = checkProp(inputs.scenario, "hazards")
            //     ? temp0.indexOf('soil_movement')
            //     : 5 ; // assume standard hazard order, with no missing hazards, soil_movement = 5

            for (let ele of Object.keys(inputs.asset.properties.materialsDict)) {
                // TODO: This won't work if the inputs are supplied by number, I think?
                inputs.asset.properties.materialsDict[ele].soil_movement = 0
            }
        }
    }

    inputs.settings = {
        method: "timeseries",
        sections: {
            inputs: "all",
        },
    }

    const analysis= await getAnalysisFromAna(inputs)

    // So Easy doesn't break
    analysis.groupId = "dummy"
    // Information only
    // eslint-disable-next-line no-undef
    analysis.repository = crConstants.repository

    return analysis
}

/**
 * Send Asset/Archetype/Scenario data to Ana for analysis, and
 * handle return values
 *
 * @param {object} inputs - Data in the format accepted by Ana.
 */
const getAnalysisFromAna = async (inputs) => {
    // TODO: Change specifications -> elements in Ana, then delete this.
    if (checkProp(inputs.archetype, "elements") & !checkProp(inputs.archetype, "specifications")) {
        inputs.archetype.specifications = inputs.archetype.elements
        delete inputs.archetype.elements
    }

    let analysisResponse
    let analysis_settings = {
        method: "POST",
        contentType: "application/json",
        // eslint-disable-next-line no-undef
        url: `${crConstants.anaHost}/v1/risk`,
        data: JSON.stringify(inputs),
        dataType: "json",
    }
    try {
        analysisResponse = await jQuery.ajax(analysis_settings)
    } catch (ex) {
        throwCRException("Error retrieving analysis", {
            exception: ex,
            settings: analysis_settings,
        })
    }

    // TODO: Fix this in ana then remove
    if (analysisResponse.response._id === "EasyXDI_CV") {
        analysisResponse.response._id = null
    }

    const analysis = analysisResponse.response

    // Move response warnings into analysis object if they're not there already.
    if (!analysis?.warnings) {
        if (analysisResponse.warnings) {
            analysis.warnings = analysisResponse.warnings
        }}

    return analysis
}

const archetype_names_dict = {
    "High Rise Flat": "HRF",
    "High Rise Structure": "HRS",
    "High Rise Office Structure": "HROS",
    "Low Rise Flat": "LRF",
    "Low Rise Structure": "LRS",
    "Low Rise Office Structure": "LROS",
    "Medium Density Attached": "MDA",
    "Medium Density Detached": "MDD",
    "Town House": "TH",
    "Town House on Ground": "TH_G",
    "Simple House": "SH",
    "Simple Office": "SO",
    Hospital: "HSP",
    "Metro Hospital": "MHSP",
    "Ambulance Station": "ASTN",
    "Activated Carbon": "WWOCU AC",
    "Advanced Water Treatment": "AWTP",
    "Aerated Lagoon": "WWTP AL",
    Arch: "BARC",
    "Arch Culvert": "ACVT",
    Barrier: "SABA",
    Beam: "SABM",
    Biofilter: "WWOCU B",
    Bollard: "SABD",
    "Box Culvert": "BCVT",
    Branch: "WWP B",
    "Bridge Pedestrian": "BPED",
    "Building Platform": "BPLT",
    Chlorination: "WCDU C",
    "Closed-circuit Television": "CCTV",
    "Composite Culvert": "CCVT",
    "Control Room": "CTRL",
    Conventional: "WWPS C",
    "Council Building": "CBLG",
    "Cut/Fill": "SLPC",
    "Dam Facility": "DFAC",
    "Dam Wall": "DWALL",
    "Desalination Plant": "DSAL",
    "DW Submersible": "WWPS DWSub",
    "Earth Walled Pond": "WW LP EWP",
    Elevated: "WR E",
    ERS: "WWP ERS",
    Fence: "SAFN",
    Filtration: "WTP F",
    "Flexible Pavement": "FLEX",
    Fluoride: "WCDU F",
    "Full Depth Asphalt F1": "SFP F1",
    Girder: "BGRD",
    "Helicopter Landing": "HELI",
    "House Connection": "WWP H",
    "Hydro Pneumatic": "WPS HP",
    "Inline Booster": "WPS IB",
    "Main Switchboard": "MSB",
    Mechanised: "WTP M",
    "Natural Lagoon": "WWTP NL",
    Nutriox: "WWCDU N",
    "Odour Lock": "WWCDU O",
    Outfall: "WWP O",
    Overbridge: "OBRG",
    Overhead: "PP O",
    Oxidation: "WWTP ODT",
    Park: "PRK",
    "Park Complex": "PRKC",
    "Pedestrian Signal": "PSIG",
    "Pipe Culvert": "PCVT",
    Plank: "BPLK",
    "Pressure Main": "WWP PM",
    Reticulation: "WP R",
    "Rigid Pavement": "RIDG",
    "Rising Main": "WWP RM",
    Rope: "SARP",
    Scour: "WP S",
    "Series Submersible": "WWPS SSub",
    "Service Main": "WP SM",
    "Sewage Mechanised": "WWTP M",
    "Signal Box": "BLSB",
    "Signal Equipment": "SGEP",
    Slab: "BSLB",
    Slopes: "SLP",
    Standpipe: "WR ST",
    Station: "BLSN",
    "Street Pole": "STPL",
    "Street Tree": "TREE",
    Structure: "SLPS",
    Submersible: "WWPS Sub",
    Substation: "PSS",
    "Sulfa Lock": "WWCDU S",
    Surface: "WR S",
    Tower: "TTWR",
    "Track Main": "TRCK",
    "Track Special": "TRKS",
    Transformer: "PTRNS",
    Trunk: "WP T",
    Truss: "BTRS",
    Tunnel: "RTUN",
    "Tunnel Service": "BLTS",
    Underbridge: "UBRG",
    Underground: "WR U",
    Unknown: "WWP U",
    UV: "WCDU U",
    Vacuum: "WWPS V",
    "Vehicle Signal": "VSIG",
    "Water Conventional": "WPS C",
    "Water Pipe Unknown": "WP U",
    "Water Weir": "WW",
    "WWP Other": "WWP O",
    "WWP Reticulation": "WWP R",
    "WWP Trunk": "WWP T",
    "SW Pit": "SPIT",
}

const results_keys = [
    "failureHazardsElements",
    "failureHazards",
    "totalFailure",
    "riskHazardsElements",
    "riskHazards",
    "totalRisk",
    "totalRiskCost",
    "kpiResidentialCustomerImpact",
    "kpiCommercialCustomerImpact",
    "kpiIndustrialCustomerImpact",
    "kpiCriticalCustomerImpact",
    "kpiOtherCustomerImpact",
]

/**
 * MASSIVE DATA MUNGING
 *
 * This converts from an Easy/Ana analysis into an Ana Risk V2 analysis,
 * or something like it.
 *
 * TODO: kill all of this. Resolve everything towards Risk V2 structure.
 * see https://gitlab.com/climaterisk/ana/issues/100
 * and https://gitlab.com/climaterisk/ana/-/merge_requests/116
 */
const munge_analysis = (analysis) => {
    // retain for future v2 alignment reference only
    // if (!checkProp(analysis, "results")) {
    //     console.warn(
    //         "FIXME: Results not found in analysis.results, retrieving from analysis.properties"
    //     )
    //     // TODO: Move to results.damage.total etc.
    //     analysis.results = {}
    //     results_keys.map((key) => {
    //         analysis.results[key] = analysis.properties[key]
    //     })
    // }

    if (checkProp(analysis, "inputs")) {
        // TODO: There might be a better way to do this?
        analysis["input_source"] = "ana_v1_schema"
    } else {
        analysis.inputs = {}
        analysis["input_source"] = "munging"

        if (checkProp(analysis, "asset")) {
            console.warn(
                "FIXME: Inputs not found in analysis.inputs, retrieving from analysis.asset"
            )
            analysis.inputs.asset = analysis.asset

            // TODO: This archetype is not complete in Easy. Does this matter?
            analysis.inputs.archetype = analysis.asset.archetype
        } else {
            console.warn(
                "FIXME: Inputs not found in analysis.inputs, retrieving partial inputs from analysis.properties"
            )
            // TODO: These are not all in the correct place.
            analysis.inputs.asset = {
                geometry: analysis.geometry,
                properties: {
                    // name: analysis.properties.name,
                    address: analysis.properties.address,
                    archetype: analysis.properties.archetype,
                    replacementCost: analysis.properties.replacementCost,
                    marketValue: analysis.properties.marketValue,
                },
            }
            analysis.inputs.archetype = {
                name: analysis.properties.archetype,
            }
        }

        if (checkProp(analysis, "scenario")) {
            console.warn(
                "FIXME: Scenario not found in analysis.inputs, retrieving from analysis.scenario"
            )
            analysis.inputs.scenario = analysis.scenario
        }
    }

    // TODO: Add warnings here, and generally move the geometry to the asset?
    if (!checkProp(analysis.inputs.asset, "geometry")) {
        if (checkProp(analysis, "geometry")) {
            console.warn("asset input doesn't contain a geometry! Copying from analysis.")
            analysis.inputs.asset.geometry = analysis.geometry
        }
    }
    // Nothing uses geometry.elevation. Elevation is available calculated in properties.
    // if (!checkProp(analysis.inputs.asset.geometry, "elevation")) {
    //     if (checkNested(analysis.inputs.asset, "properties", "totalHeight")) {
    //         // TODO: Easy doesn't have asset.properties. Consolidate schemas and fix.
    //         console.warn("asset doesn't have elevation, calculating from totalHeight")
    //         analysis.inputs.asset.geometry.elevation =
    //             analysis.inputs.asset.properties.totalHeight -
    //             analysis.inputs.asset.properties.heightAboveGround
    //     }
    // }

    // check for elevation existing in geometry or inputs.asset.properties
    if (
        !checkNested(analysis.inputs.asset, "geometry", "elevation") &&
        !checkNested(analysis.inputs.asset.properties, "elevation")
    ) {
        console.warn("FIXME: Asset does not have an elevation!")
    }

    if (checkNested(analysis, "inputs", "archetype")) {
        if (!checkProp(analysis.inputs.archetype, "acronym")) {
            console.warn(
                "FIXME: Archetype acronym missing in analysis.inputs.archetype," +
                    " retrieving from dictionary"
            )
            analysis.inputs.archetype.acronym = archetype_names_dict[analysis.properties.archetype]

            if (analysis.inputs.archetype.acronym == undefined) {
                console.warn(
                    `FIXME: Unknown archetype "${analysis.properties.archetype}".` +
                        "Ask CR to add it to acrobase!"
                )
            }
        }

        if (!checkProp(analysis.inputs.archetype, "name")) {
            // This is used in the details tab.
            if (checkProp(analysis.inputs.archetype, "archetype")) {
                console.warn(`FIXME:  Archetype in incorrect format, should have "name" property`)
                analysis.inputs.archetype.name = analysis.inputs.archetype.archetype
            } else {
                analysis.inputs.archetype.name = analysis.properties.archetype
            }
        }
    }

    return analysis
}

/**
 * Get land elevation at location
 *
 * TODO: This is a wrapper around Google at the moment, it should eventually
 * attept to retrieve information from the Elevation Service, and fall back to
 * Google.
 *
 * @param {number} lat - latitude
 * @param {number} lon - longitude
 */
const getElevation = async (lat, lon) => {
    try {
        const elevationResponse = await jQuery.ajax({
            url: `https://api-climaterisk.opentopodata.org/v1/climaterisk-global?x-api-key=${crConstants.ElevationApiKey}&locations=${lat},${lon}`,
            crossDomain: true,
        })
        if (elevationResponse.status == "OK") {
            return {
                elevation: elevationResponse.results[0].elevation,
                dataset: elevationResponse.results[0].dataset,
            }
        }
    } catch (ex) {
        console.warn("Elevation API Failure, falling back to Google Elevation")
    }

    return getElevationFromGoogle(lat, lon)
}

/**
 * Google elevation getter wrapper.
 *
 * @param {number} lat - latitude
 * @param {number} lon - longitude
 */
const getElevationFromGoogle = async (lat, lon) => {
    let elevation
    try {
        elevation = await googleElevation(lat, lon)
    } catch (ex) {
        // TODO: Retain URL details somehow?
        throwCRException("Error retrieving elevation data (Google)", {
            exception: ex,
        })
    }

    return elevation
}

/**
 * Returns analysis title chosen by user
 *
 * @param {object} analysis -  analysis object
 * @param {string} indexInGroup - group index
 * @param {number} length -
 *
 * @returns {string} - revision name
 */
const getAnalysisRevisionTitle = (analysis, indexInGroup, length = 1) => {
    // Returns title of current revision
    // If no revision returns ""
    if (analysis == undefined) return ""

    let address = ""
    if (checkNested(analysis, "properties")) {
        address = analysis.properties.address || ""
    } else {
        address = analysis.inputs.asset.properties.address || ""
    }

    let title = ""
    if (checkNested(analysis, "title")) {
        title = analysis.title || ""
    } else {
        title = ""
    }

    const minLen = Math.min(address.length, title.length)
    let titleIndex = 0
    while (titleIndex < minLen && address[titleIndex] == title[titleIndex]) {
        titleIndex++
    }

    if (!title.substring(titleIndex) && indexInGroup === 0 && length === 1) return ""

    return title.substring(titleIndex) || `Revision ${indexInGroup + 1}`
}

export { doAnalysis, munge_analysis, getAnalysisFromAna, getElevation, getAnalysisRevisionTitle }
