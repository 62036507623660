import React from "react"
import autoBind from "react-autobind"
import { Text, View, Page, Image } from "@react-pdf/renderer"
import PropTypes from "prop-types"

import styled from "@react-pdf/styled-components"
import {
    Subtitle,
    SubtitleLarge,
    Paragraph,
    styles,
    Br1,
    SolidRedBox,
    ThinRedBox,
} from "../PdfReportComponents/Shared.jsx"

class AnalysisResults extends React.Component {
    static propTypes = {
        analysis: PropTypes.object.isRequired,
        VARCharts: PropTypes.string,
        pieChartsForAnnualVARStartYear: PropTypes.string,
        pieChartsForAnnualVARMortgageTerm: PropTypes.string,
        pieChartsForAnnualVAREndYear: PropTypes.string,
        maxFailureProbabilityToDate: PropTypes.string,
        annualFailureProbability: PropTypes.string,
        pieChartsForAnnualFailureProbabilityStartYear: PropTypes.string,
        pieChartsForAnnualFailureProbabilityMortgageTerm: PropTypes.string,
        pieChartsForAnnualFailureProbabilityEndYear: PropTypes.string,
        vulnerabilityStartYear: PropTypes.string,
        vulnerabilityMortgageTerm: PropTypes.string,
        vulnerabilityEndYear: PropTypes.string,
        floodRiverine: PropTypes.string,
        inundation: PropTypes.string,
        forestFire: PropTypes.string,
        soilSubsidence: PropTypes.string,
        extremeWind: PropTypes.string,
        extremeHeat: PropTypes.string,
        freezeThaw: PropTypes.string,
        floodSurfaceWater: PropTypes.string,
        cycloneWind: PropTypes.string,
    }
    constructor(props) {
        super(props)
        autoBind(this)
    }
    /**
     *  @param {string} year
     */
    calculateRating = (year) => {
        const yearIndex = year ? year - this.props.analysis.inputs.scenario.startYear : 0
        const riskFraction = 100 * this.props.analysis.properties.totalRisk[yearIndex]

        if (riskFraction < 0.2) return "A"
        if (riskFraction < 1) return "B"
        return "C"
    }

    calcHighestYear = () => {
        const totalRisk = this.props.analysis.properties.totalRisk
        const highestReached = Math.max(...totalRisk)

        const yearIndex = totalRisk.indexOf(highestReached)

        return this.props.analysis.inputs.scenario.startYear + yearIndex
    }

    render() {
        const {
            analysis,
            VARCharts,
            pieChartsForAnnualVARStartYear,
            pieChartsForAnnualVARMortgageTerm,
            pieChartsForAnnualVAREndYear,
            maxFailureProbabilityToDate,
            annualFailureProbability,
            pieChartsForAnnualFailureProbabilityStartYear,
            pieChartsForAnnualFailureProbabilityMortgageTerm,
            pieChartsForAnnualFailureProbabilityEndYear,
            vulnerabilityStartYear,
            vulnerabilityMortgageTerm,
            vulnerabilityEndYear,
            floodRiverine,
            inundation,
            forestFire,
            soilSubsidence,
            extremeWind,
            extremeHeat,
            freezeThaw,
            floodSurfaceWater,
            cycloneWind
        } = this.props

        const { assetLifeEnd } = analysis.inputs.asset.properties
        const presentRating = this.calculateRating()
        const highestYear = this.calcHighestYear()
        const selectedYearRating = this.calculateRating(highestYear)

        const ratingPhrase = {
            A: "Low",
            B: "Medium",
            C: "High",
        }

        const ImageWrapper = styled.View`
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        `

        const PieChartImageWrapper = styled.View`
            width: 30%;
        `
        const ColumnHead = styled.View`
            height: 20;
            margin-top: 20;
        `

        const BlockCell = styled.View`
            height: 20;
            margin-top: 20;
        `

        return (
            <React.Fragment>
                <Page size="A4" style={styles.customPages}>
                    <View
                        style={[
                            styles.redBackground,
                            { paddingVertical: 10, paddingHorizontal: 10, marginBottom: 10 },
                        ]}>
                        <SubtitleLarge style={styles.whiteText}>3. ANALYSIS RESULTS</SubtitleLarge>
                    </View>

                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}> 3.1 INTRODUCTION</SubtitleLarge>
                    </View>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                This section shows the financial impacts of extreme weather and
                                climate change on the representative asset, using two key
                                indicators: Percentage of Value At Risk (VAR%) and Failure
                                Probability (FP).
                            </Paragraph>
                            <Br1></Br1>
                        </View>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                Based on these two indicators, XDI provides an overall Asset Risk
                                Rating, Maximum VAR% and Failure Probabilities for all hazards
                                (aggregated) and for each individual hazard. Vulnerability
                                Diagnostics on the component elements of the asset archetype provide
                                further insights into failure modes. These can be customised within
                                EasyXDI online if required.
                            </Paragraph>
                        </View>
                    </View>

                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            Percentage of Value At Risk (VAR%) - using Annual Cost of Damage
                        </Subtitle>
                    </View>
                    <Br1></Br1>
                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                The Percentage of Value At Risk (VAR%) is the annual cost of damage
                                (also called Technical Insurance Premium) caused to an asset by
                                extreme weather, expressed as a percentage of a single asset’s
                                replacement cost, specified for a 1 year period with no discounting
                                of the TIP or the asset replacement cost.
                            </Paragraph>
                        </View>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                Because the replacement value of the actual asset replacement will
                                vary, EasyXDI converts the cost of damage to a percentage of the
                                Value At Risk (VAR%) rather than provide a dollar figure.
                            </Paragraph>

                            <Br1></Br1>
                            <Paragraph>Therefore:</Paragraph>
                            <Br1></Br1>
                            <SolidRedBox style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
                                <Paragraph>Percentage of Value At Risk (VAR%) = </Paragraph>
                                <Paragraph>
                                    Technical Insurance Premium / asset replacement cost
                                </Paragraph>
                            </SolidRedBox>
                            <Br1></Br1>
                        </View>
                    </View>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            Failure Probability (FP) - probability of an event that
                            will stop the asset working
                        </Subtitle>
                    </View>
                    <Br1></Br1>
                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                Failure Probability is the annual probability of an event occurring
                                that will cause the asset or its components to stop working. This
                                can occur due to physical damage or due to the asset stopping
                                working without damage. Eg.: an airconditioner might fail because
                                the ambient temperature exceeds the operational threshold of its
                                electronics.
                            </Paragraph>
                            <Br1></Br1>
                            <Paragraph>
                                Failure probability is an important indicator because of the
                                consequences related to the asset failing to operate.
                            </Paragraph>
                        </View>
                        <View style={[styles.ColumnLayout2, styles.ColumnLayout2Left]}>
                            <Br1></Br1>
                            <Paragraph>
                                This is especially true for productive assets such as business
                                premises or critical infrastructure where there may be significant
                                lost income or customer disruptions when the asset stops working.
                            </Paragraph>

                            <Br1></Br1>
                            <Paragraph>
                                The mechanisms by which as asset or its component elements are
                                caused to fail by a hazard are further explored in Vulnerability
                                Diagnostics.
                            </Paragraph>
                            <Paragraph>For more information see Section: Methodology.</Paragraph>
                        </View>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>3.2 XDI ASSET RATING</SubtitleLarge>
                    </View>

                    <Br1></Br1>
                    <Paragraph>
                        Using VAR% as an indicator on the possible availability and affordability of
                        insurance, EasyXDI creates a Risk Rating associated with the chosen asset
                        type at its selected location now and at the end of the asset’s lifespan.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        A representative asset (building: {analysis.properties.archetype}) at the
                        selected location has a {ratingPhrase[selectedYearRating]} XDI Risk Rating
                        over the expected asset lifetime (ending {assetLifeEnd}). The highest level
                        of risk (%VAR) is reached in {highestYear}.
                    </Paragraph>
                    <Br1></Br1>
                    <Br1></Br1>
                    <View>
                        <View style={styles.MultiCol}>
                            <View style={[styles.ColumnLayout2, { width: "40%" }]}>
                                <Br1></Br1>
                                <View style={styles.circle}>
                                    <Paragraph style={styles.circleHeading}>
                                        {presentRating}
                                    </Paragraph>

                                    <Paragraph style={styles.circleValue}>
                                        {analysis.inputs.scenario.startYear}
                                    </Paragraph>
                                </View>
                            </View>
                            <View style={[styles.ColumnLayout2, { width: "20%" }]}>
                                <Br1></Br1>
                                <Image
                                    style={{ margin: "auto" }}
                                    src="/static/images/report/arrow.png"
                                />
                            </View>
                            <View style={[styles.ColumnLayout2, { width: "40%" }]}>
                                <Br1></Br1>
                                <View style={styles.circle}>
                                    <Paragraph style={styles.circleHeading}>
                                        {selectedYearRating}
                                    </Paragraph>

                                    <Paragraph style={styles.circleValue}>{highestYear}</Paragraph>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Paragraph>
                        XDI ratings are calculated from the Value At Risk % (annual average cost of
                        damage divided by asset replacement cost) as follows:
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "10%" }]}>
                            <View style={styles.smallCircle}>
                                <Paragraph style={styles.smallCircleLable}>A</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "90%" }]}>
                            <View style={styles.cirleRightText}>
                                <Paragraph style={styles.darkheading}>
                                    {"Low Risk = %VAR< 0.2"}
                                </Paragraph>

                                <Paragraph>May be insurable at reasonable cost.</Paragraph>
                            </View>
                        </View>
                    </View>
                    <Br1></Br1>
                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "10%" }]}>
                            <View style={styles.smallCircle}>
                                <Paragraph style={styles.smallCircleLable}>B</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "90%" }]}>
                            <View style={styles.cirleRightText}>
                                <Paragraph style={styles.darkheading}>
                                    {"Moderate Risk = 0.2 < %VAR < 1"}
                                </Paragraph>

                                <Paragraph>May lead to higher insurance costs.</Paragraph>
                            </View>
                        </View>
                    </View>
                    <Br1></Br1>
                    <View style={styles.MultiCol}>
                        <View style={[styles.ColumnLayout2, { width: "10%" }]}>
                            <View style={styles.smallCircle}>
                                <Paragraph style={styles.smallCircleLable}>C</Paragraph>
                            </View>
                        </View>
                        <View style={[styles.ColumnLayout2, { width: "90%" }]}>
                            <View style={styles.cirleRightText}>
                                <Paragraph style={styles.darkheading}>
                                    {"High Risk = %VAR > 1"}
                                </Paragraph>

                                <Paragraph>
                                    Insurance may be high cost or unavailable unless adaptation
                                    actions are undertaken.
                                </Paragraph>
                            </View>
                        </View>
                    </View>

                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>

                    <ThinRedBox style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
                        <Paragraph>
                            The XDI ratings follow the Federal Emergency Management Agency (FEMA)
                            designations that are used for pricing a large number of Insurance
                            premiums in the USA. XDI has used FEMA’s probability method and extended
                            it to include a wider set of hazards. The XDI Rating results therefore
                            provide an insight into the possible longer-term availability and cost
                            of insurance. For any real asset, availability and costs should be
                            obtained from a commercial insurance provider.
                        </Paragraph>
                    </ThinRedBox>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>
                            3.3 PERCENTAGE OF VALUE AT RISK (VAR%) - ALL HAZARDS
                        </SubtitleLarge>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        This section shows maximum and annual VAR% for all hazards.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Maximum Value At Risk (VAR%)</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Paragraph>
                        This chart shows the contribution of each hazard to the Maximum VAR% and the
                        contribution each hazard makes to VAR% for each year.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>
                    {VARCharts && (
                        <ImageWrapper>
                            <Image style={styles.hazardImage} src={VARCharts} />
                        </ImageWrapper>
                    )}

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <Br1></Br1>
                    <Paragraph>
                        Starting in 2020, the Max VAR% shows the highest VAR% reached up to a given
                        date. This can be thought of as the record reached by an athlete, which
                        stays unchanged until the record is broken and pushed higher. Max VAR% is an
                        important reference for stress testing an asset, because climate models may
                        not be accurate at assessing the precise time at which climate indicators
                        increase or decrease. This chart alerts the user to the maximum VAR% that
                        has been seen up until that date. This number may be the most appropriate to
                        use for adaptation planning.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>
                    <Paragraph>
                        Note that downward fluctuations sometimes appear due to climate model
                        outputs, which VAR% follows. In some cases, model outputs are aggregated
                        into 20 year blocks, in which case curve-fitting is used to interpolate and
                        extrapolate the annual behaviour.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            Pie Charts for Annual VAR% by hazard for{" "}
                            {analysis.inputs.scenario.startYear}, 2050, 2100
                        </Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        These charts show annual VAR% divided into each hazard for three time
                        periods.
                    </Paragraph>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>
                    {pieChartsForAnnualVARStartYear &&
                        pieChartsForAnnualVARMortgageTerm &&
                        pieChartsForAnnualVAREndYear && <>
                        <View style={{ width: "100%" }}>
                            <Image
                                style={styles.pieChartImage}
                                src={pieChartsForAnnualVARStartYear}
                            />
                        </View>
                        <View style={{ width: "100%" }}>
                            <Image
                                style={styles.pieChartImage}
                                src={pieChartsForAnnualVARMortgageTerm}
                            />
                        </View>
                        <View style={{ width: "100%" }}>
                            <Image
                                style={styles.pieChartImage}
                                src={pieChartsForAnnualVAREndYear}
                            />
                        </View>
                    </>}

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>
                            3.4 FAILURE PROBABILITY - ALL HAZARDS
                        </SubtitleLarge>
                    </View>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            Maximum Failure probability to date
                        </Subtitle>
                    </View>

                    <Br1></Br1>
                    <Paragraph>
                        This chart shows the contribution of each hazard to the Maximum Failure
                        Probability.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    {maxFailureProbabilityToDate && (
                        <ImageWrapper>
                            <Image style={styles.hazardImage} src={maxFailureProbabilityToDate} />
                        </ImageWrapper>
                    )}

                    <Br1></Br1>
                    <Br1></Br1>
                    <Paragraph>
                        Starting in 2020, the Max Failure Probability shows the highest FP reached
                        over the period. This can be thought of as the record reached by an athlete,
                        which stays unchanged until the record is broken and pushed higher. This
                        includes events which cause disruption without damage (e.g. extreme heat)
                        and those that cause disruption while also damaging assets (e.g. flood).
                    </Paragraph>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            Annual Failure probability to date
                        </Subtitle>
                    </View>

                    <Br1></Br1>
                    <Paragraph>
                        This chart shows the contribution each hazard makes to the annual failure
                        probability.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    {annualFailureProbability && (
                        <ImageWrapper>
                            <Image style={styles.hazardImage} src={annualFailureProbability} />
                        </ImageWrapper>
                    )}

                    <Br1></Br1>
                    <Br1></Br1>

                    <Paragraph>
                        Note that downward fluctuations sometimes appear due to climate model
                        outputs. In some cases, model outputs are aggregated into 20 year blocks, in
                        which case curve-fitting is used to interpolate and extrapolate the annual
                        behaviour.
                    </Paragraph>

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View>
                        <Br1></Br1>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>
                                Pie Charts for Annual FP by hazard for{" "}
                                {analysis.inputs.scenario.startYear}, 2050, 2100
                            </Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>
                            These charts show annual Failure Probability divided into each hazard
                            for three time periods.
                        </Paragraph>

                        <Br1></Br1>
                        <Br1></Br1>

                        {pieChartsForAnnualFailureProbabilityStartYear &&
                            pieChartsForAnnualFailureProbabilityMortgageTerm &&
                            pieChartsForAnnualFailureProbabilityEndYear && <>
                            <View style={{ width: "100%" }}>
                                <Image
                                    style={styles.pieChartImage}
                                    src={pieChartsForAnnualFailureProbabilityStartYear}
                                />
                            </View>
                            <View style={{ width: "100%" }}>
                                <Image
                                    style={styles.pieChartImage}
                                    src={
                                        pieChartsForAnnualFailureProbabilityMortgageTerm
                                    }
                                />
                            </View>
                            <View style={{ width: "100%" }}>
                                <Image
                                    style={styles.pieChartImage}
                                    src={pieChartsForAnnualFailureProbabilityEndYear}
                                />
                            </View>
                        </>}
                    </View>

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>
                            3.5 VULNERABILITY DIAGNOSTICS
                        </SubtitleLarge>
                    </View>

                    <Br1></Br1>
                    <Paragraph>
                        This section helps to understand which material elements of the asset
                        archetype are failing and why. The graph shows hazard impacts (colour coded)
                        on each of the construction elements in the archetype for three time
                        periods.
                    </Paragraph>
                    <Br1></Br1>

                    <Paragraph>
                        The Y Axis lists all of the construction elements analysed for hazard
                        impacts. The X Axis quantifies each hazards contribution to the composition
                        percentage of the the hazard impacts for each of the elements.
                    </Paragraph>
                    <Br1></Br1>

                    <Paragraph>
                        Diagnosing vulnerability helps decision makers to fine tune maintenance,
                        schedule upgrades, or plan for adaptation.
                    </Paragraph>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>1990</Subtitle>
                    </View>

                    <Br1></Br1>
                    {vulnerabilityStartYear && (
                        <ImageWrapper>
                            <Image style={styles.vulnerabilityImage} src={vulnerabilityStartYear} />
                        </ImageWrapper>
                    )}
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>2050</Subtitle>
                    </View>

                    <Br1></Br1>
                    {vulnerabilityMortgageTerm && (
                        <ImageWrapper>
                            <Image
                                style={styles.vulnerabilityImage}
                                src={vulnerabilityMortgageTerm}
                            />
                        </ImageWrapper>
                    )}
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>2100</Subtitle>
                    </View>

                    {vulnerabilityEndYear && (
                        <ImageWrapper>
                            <Image style={styles.vulnerabilityImage} src={vulnerabilityEndYear} />
                        </ImageWrapper>
                    )}

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.darkGreyBackground}>
                        <SubtitleLarge style={styles.whiteText}>
                            3.6 HAZARDS BREAKDOWN
                        </SubtitleLarge>
                    </View>

                    <Br1></Br1>
                    <Paragraph>
                        This section displays key indicators for each hazard analysed on the asset
                        archetype.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Riverine Flooding</Subtitle>
                    </View>
                    <Br1></Br1>

                    <View wrap={false}>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {floodRiverine && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={floodRiverine} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>

                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Coastal Inundation</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {inundation && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={inundation} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Forest Fire</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {forestFire && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={forestFire} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>

                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Soil Movement</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {soilSubsidence && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={soilSubsidence} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Extreme Wind</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {extremeWind && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={extremeWind} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>

                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Extreme Heat</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {extremeHeat && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={extremeHeat} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>
                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Freeze-Thaw</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {freezeThaw && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={freezeThaw} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>
                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Flood (Surface Water)</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {floodSurfaceWater && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={floodSurfaceWater} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>
                    <View wrap={false}>
                        <View style={styles.lightGreyBackground}>
                            <Subtitle style={styles.whiteText}>Tropical Cyclone Wind</Subtitle>
                        </View>
                        <Br1></Br1>
                        <Paragraph>VAR% and Failure Probability (Maximum + Annual)</Paragraph>
                        <Br1></Br1>
                        {cycloneWind && (
                            <ImageWrapper>
                                <Image style={styles.hazardImage} src={cycloneWind} />
                            </ImageWrapper>
                        )}
                        <Br1></Br1>
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
            </React.Fragment>
        )
    }
}

export default AnalysisResults
