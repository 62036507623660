import React from "react";
import autoBind from "react-autobind";
import { Link, View, StyleSheet, Page, Image } from "@react-pdf/renderer";
import PropTypes from 'prop-types'
import styled from "@react-pdf/styled-components";

import appState from '../../CoreFiles/state.js'
import { getAnalysisRevisionTitle } from "../../analyses";
import * as moment from "moment";

class Cover extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        analysis: PropTypes.object,
        locationImage: PropTypes.string,
        analysisGroup: PropTypes.array
    }

    _isMounted = false;

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { account, analysis, locationImage, analysisGroup } = this.props;

        const handleGetDate = function () {
            if (analysis.createdAt !== undefined) {
                return moment(analysis.createdAt["$date"]).format("DD/MM/YYYY");
            } else {
                const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
                return new Date().toLocaleDateString("en-AU", options);
            }
        };

        const styles = StyleSheet.create({
            logo: {
                marginTop: "10%",
                marginBottom: "7%",
                marginLeft: "auto",
                marginRight: "auto",
                height: 48,
            },
            coverpage: { backgroundColor: "white" },
            footerLinks: {
                color: "white",
                fontSize: 18,
                textDecoration: "none",
                fontFamily: "Roboto Condensed Bold",
            },
            revisionsTitle: { marginTop: "10px" },
            InforSection: { marginTop: "20px" },
        });
        const Title = styled.Text`
            font-size: 34;
            text-align: center;
            color: #b51218;
            marginbottom: 0px;
            font-family: "Roboto Condensed Bold";
        `;
        const UserInfo = styled.Text`
            font-size: 16px;
            text-align: center;
            color: black;
            font-family: "Roboto Condensed";
        `;
        const Footer = styled.View`
            left: 0px;
            right: 0px;
            color: grey;
            bottom: 0px;
            font-size: 12px;
            position: absolute;
            text-align: center;
            margin: auto;
        `;
        const RedBar = styled.View`
            width: 100%;
            height: 20px;
            background-color: #b51218;
        `;
        const RedBarArrow = styled.View`
            width: 0px;
            height: 0px;
            borderstyle: solid;
            border-top-width: 20px;
            border-right-width: 22px;
            border-bottom-width: 1px;
            border-left-width: 22px;
            border-top-color: #b51218;
            border-right-color: #58595b;
            border-left-color: #58595b;
            border-bottom-color: #58595b;
            margin: auto;
            lineheight: 0px;
        `;
        const RedBarArrowWrapper = styled.View`
            height: 19px;
            width: 100%;
            background-color: #58595b;
            margin-top: -1px;
        `;
        const GreyBarArrowWrapper = styled.View`
            min-height: 100px;
            width: 100%;
            background-color: #58595b;
            margin-top: -1px;
        `;
        const FooterLinksWrapper = styled.View`
            left: 0px;
            right: 0px;
            color: grey;
            bottom: 5px;
            position: absolute;
            padding: 10px;
        `;
        const CoverImageWrapper = styled.View`
            border-style: solid;
            border-top-width: 1px;
            border-right-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-top-color: #b51218;
            border-right-color: #b51218;
            border-left-color: #b51218;
            border-bottom-color: #b51218;
            width: 400px;
            height: 250px;
            margin-top: 8%;
            margin-bottom: 4%;
            margin-left: auto;
            margin-right: auto;
        `;
        const FooterImage = styled.Image`
            width: 100%;
            height: 119pt;
        `;

        return (
            <Page size="A4" style={styles.coverpage}>
                <Image style={styles.logo} src="/static/images/report/East_XDI_logos.png" />

                <Title style={{ fontWeight: "bold" }}>CLIMATE RISK</Title>
                <Title>ANALYSIS REPORT</Title>
                <View style={styles.InforSection}>
                    <UserInfo>Prepared for: {account ? account.username : "Globe Interface"}</UserInfo>
                    <UserInfo>{analysis.properties.address}</UserInfo>
                    <UserInfo>{handleGetDate()}</UserInfo>
                    {analysisGroup && <UserInfo style={styles.revisionsTitle}>
                        {getAnalysisRevisionTitle(
                            analysisGroup[analysisGroup.findIndex((a) => a._id == analysis._id)],
                            analysisGroup.findIndex((a) => a._id == analysis._id),
                            analysisGroup.length
                        )}
                    </UserInfo>}
                </View>

                {locationImage && (
                    <CoverImageWrapper>
                        <Image style={styles.cover} src={locationImage} />
                    </CoverImageWrapper>
                )}

                <Footer>
                    <FooterImage src="/static/images/report/bottom_border.png" />
                    <FooterLinksWrapper>
                        <Link style={styles.footerLinks} src="https://easyxdi.com">
                            www.easyxdi.com
                        </Link>
                        <Link style={styles.footerLinks} src="https://xdi.systems">
                            www.xdi.systems
                        </Link>
                    </FooterLinksWrapper>
                </Footer>
            </Page>
        );
    }
}

export default Cover;
