import React from "react";
import { Button } from "react-bootstrap";
import autoBind from "react-autobind";
import PropTypes from 'prop-types'
import {
    Document,
    Font,
    pdf,
} from "@react-pdf/renderer";

import appState from '../../CoreFiles/state.js'
import Cover from "../PdfReportComponents/Cover.jsx";
import Disclaimer from "../PdfReportComponents/Disclaimer.jsx";
import Caveats from "../PdfReportComponents/Caveats.jsx";
import TableOfContents from "../PdfReportComponents/TableOfContents.jsx";
import Introduction from "../PdfReportComponents/Introduction.jsx";
import AnalysisSettings from "../PdfReportComponents/AnalysisSettings.jsx";
import AnalysisResults from "../PdfReportComponents/AnalysisResults.jsx";
import AnalysisMethods from "../PdfReportComponents/AnalysisMethods.jsx";
import Conclusion from "../PdfReportComponents/Conclusion.jsx";

import html2canvas from "html2canvas";
window.html2canvas = html2canvas;

class PDFDownloadLinkWrapper extends React.Component {
    static propTypes = {
        analysis: PropTypes.object,
        analysisGroup: PropTypes.array,
        account: PropTypes.object,
        filename: PropTypes.string,
        locationImage: PropTypes.string,
        satelliteViewImage: PropTypes.string,
        VARCharts: PropTypes.string,
        pieChartsForAnnualVARStartYear: PropTypes.string,
        pieChartsForAnnualVARMortgageTerm: PropTypes.string,
        pieChartsForAnnualVAREndYear: PropTypes.string,
        maxFailureProbabilityToDate: PropTypes.string,
        annualFailureProbability: PropTypes.string,
        pieChartsForAnnualFailureProbabilityStartYear: PropTypes.string,
        pieChartsForAnnualFailureProbabilityMortgageTerm: PropTypes.string,
        pieChartsForAnnualFailureProbabilityEndYear: PropTypes.string,
        vulnerabilityStartYear: PropTypes.string,
        vulnerabilityMortgageTerm: PropTypes.string,
        vulnerabilityEndYear: PropTypes.string,
        floodRiverine: PropTypes.string,
        inundation: PropTypes.string,
        forestFire: PropTypes.string,
        soilSubsidence: PropTypes.string,
        extremeWind: PropTypes.string,
        extremeHeat: PropTypes.string,
        freezeThaw: PropTypes.string,
        floodSurfaceWater: PropTypes.string,
        cycloneWind: PropTypes.string,
        restrictRender: PropTypes.func,
        onComplete: PropTypes.func,
        onDownload: PropTypes.func,
        materials: PropTypes.object,
    }
    _debugReportsBlobAccess = crConstants.debugReportsBlobAccess;

    constructor(props) {
        super(props);
        Font.register({
            family: "Roboto Condensed",
            src: `/static/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf`,
        });
        Font.register({
            family: "Roboto Condensed Bold",
            src: `/static/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf`,
        });
        Font.registerHyphenationCallback((word) => {
            //   const middle = Math.floor(word.length / 2);
            //   const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

            //   // Check console to see words parts
            //   console.log(word, parts);

            //   return parts;
            return [word];
        });

        this.state = {
            renderingStarted: false,
            blobURL: null,
        };
        autoBind(this);
    }

    componentDidMount() {
        this.getPDFBlob();
    }

    componentDidUpdate() {
        if (this.state.blobURL !== null) {
            this.props.onComplete();
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidCatch(error, errorInfo) {
        console.log("PDFDownloadLinkWrapper error:", error, errorInfo);
    }
    /**
     *
     * @param {object} blob
     * @param {string} fileName
     */
    downloadPDF(blob, fileName) {
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        }
        this.props.onDownload();
    }
    async getPDFBlob() {
        const {
            analysis,
            analysisGroup,
            account,
            locationImage,
            satelliteViewImage,
            VARCharts,
            pieChartsForAnnualVARStartYear,
            pieChartsForAnnualVARMortgageTerm,
            pieChartsForAnnualVAREndYear,
            maxFailureProbabilityToDate,
            annualFailureProbability,
            pieChartsForAnnualFailureProbabilityStartYear,
            pieChartsForAnnualFailureProbabilityMortgageTerm,
            pieChartsForAnnualFailureProbabilityEndYear,
            vulnerabilityStartYear,
            vulnerabilityMortgageTerm,
            vulnerabilityEndYear,
            floodRiverine,
            inundation,
            forestFire,
            soilSubsidence,
            extremeWind,
            extremeHeat,
            freezeThaw,
            floodSurfaceWater,
            cycloneWind,
            materials
        } = this.props;

        const title = "easyXDI";
        const author = "Easy XDI";
        const subject = "Easy XDI Analysis Report";
        const MyDoc = (
            <Document title={title} author={author} subject={subject}>
                <Cover
                    analysis={analysis}
                    analysisGroup={analysisGroup}
                    account={account}
                    locationImage={locationImage}
                    // locationImageStatic="/report/Screen_Shot_2019-11-22_at_4.12.58_pm1.png"
                />
                <Disclaimer />
                <Caveats />
                <TableOfContents />
                <Introduction />
                <AnalysisSettings
                    locationImage={locationImage}
                    satelliteViewImage={satelliteViewImage}
                    analysis={analysis}
                    fieldDefaultValues={appState.fieldDefaultValues}
                />
                <AnalysisResults
                    analysis={analysis}
                    fieldDefaultValues={appState.fieldDefaultValues}
                    VARCharts={VARCharts}
                    pieChartsForAnnualVARStartYear={pieChartsForAnnualVARStartYear}
                    pieChartsForAnnualVARMortgageTerm={pieChartsForAnnualVARMortgageTerm}
                    pieChartsForAnnualVAREndYear={pieChartsForAnnualVAREndYear}
                    maxFailureProbabilityToDate={maxFailureProbabilityToDate}
                    annualFailureProbability={annualFailureProbability}
                    pieChartsForAnnualFailureProbabilityStartYear={
                        pieChartsForAnnualFailureProbabilityStartYear
                    }
                    pieChartsForAnnualFailureProbabilityMortgageTerm={
                        pieChartsForAnnualFailureProbabilityMortgageTerm
                    }
                    pieChartsForAnnualFailureProbabilityEndYear={
                        pieChartsForAnnualFailureProbabilityEndYear
                    }
                    vulnerabilityStartYear={vulnerabilityStartYear}
                    vulnerabilityMortgageTerm={vulnerabilityMortgageTerm}
                    vulnerabilityEndYear={vulnerabilityEndYear}
                    floodRiverine={floodRiverine}
                    inundation={inundation}
                    forestFire={forestFire}
                    soilSubsidence={soilSubsidence}
                    extremeWind={extremeWind}
                    extremeHeat={extremeHeat}
                    freezeThaw={freezeThaw}
                    floodSurfaceWater={floodSurfaceWater}
                    cycloneWind={cycloneWind}
                />

                <AnalysisMethods />
                <Conclusion />
            </Document>
        );
        const objectURL = await pdf(MyDoc).toBlob();
        const blobURL = URL.createObjectURL(objectURL);

        this.setState({ blobURL: blobURL });
        console.log("_debugReportsBlobAccess", this._debugReportsBlobAccess);
        if (this._debugReportsBlobAccess) {
            const win = window.open(blobURL, { filename: this.props.filename }, "_blank");
            focus && win.focus();
        }
    }
    render() {
        const { onDownload, filename } = this.props;
        const downloadPDF = this.downloadPDF;

        const downloadOnIE = (blob, fileName) => () => {
            onDownload();
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, fileName);
            }
        };

        const { blobURL } = this.state;
        return (
            <React.Fragment>
                {blobURL !== null && (
                    <div style={{
                        display:"flex",
                        justifyContent:"center",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <h4>
                            Report Generation Complete!
                        </h4>
                        <p>The report is complete and can be opened by clicking the link below:</p>
                        <Button
                            style={{color:"#fff", width:"10rem", cursor: "pointer"}}
                            className="d-block"
                            download={filename}
                            href={blobURL}
                            onClick={downloadOnIE(blobURL)}
                        >
                            Download report
                        </Button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default PDFDownloadLinkWrapper;
