/**
 * Acrobase data handling (archetypes and materials)
 */

import _ from "lodash"
import { observable } from "mobx"

import { checkProp } from "./utils"
import { throwCRException } from "./lumberjack"

class ArchetypeCache {
    @observable archetypes = {}
    @observable materials = {}
}

const archCache = new ArchetypeCache()

// TODO: Does this need to exist? it's a pointless wrapper around the function below, I think.
const getArchetype = async (archetype_name) => {
    let archetype
    try {
        archetype = await getArchetypeData(archetype_name)
    } catch (ex) {
        // re-throw more detailed error caught during ajax call
        if (ex.name == "CRException") throw ex

        throwCRException("Error processing archetype data", { exception: ex })
    }

    return archetype
}

/**
 * Get archetype data from acrobase, and munge it.
 *
 * @param {number} acronym - archetype machine name
 */
const getArchetypeData = async (acronym="SH") => {
    if (!archCache.archetypes[acronym]) {
        let archetype_settings = {
            url:
                // eslint-disable-next-line no-undef
                `${crConstants.acrobaseHost}/v1/archetype?acronym=${acronym}` +
                "&include=elements+materials+fields&hazards-as-list&expand_aliases=true",
        }
        let archetype
        try {
            archetype = await jQuery.ajax(archetype_settings)
        } catch (ex) {
            throwCRException("Error retrieving archetype data", {
                exception: ex,
                settings: archetype_settings,
            })
        }

        // Key by machine field name
        // TODO: Update this with acrobase.
        archetype.fields = archetype.fields.reduce((res, field) => {
            return { ...res, [field.machine]: field }
        }, {})

        archetype.fields.buildYear.default = new Date().getFullYear() - archetype.fields.age.default

        // TODO: Change this in acrobase, then delete
        // Changes old style specifications to new style
        if (Array.isArray(archetype.specifications)) {
            archetype.specifications = _.reduce(
                archetype.specifications,
                (out, value) => {
                    const name = value.name
                    return {
                        ...out,
                        [name]: {
                            ...value,
                            exposures: {
                                "flood_riverine": value.exposures[0],
                                "inundation": value.exposures[1],
                                "heat": value.exposures[2],
                                "forest_fire": value.exposures[3],
                                "wind": value.exposures[4],
                                "soil_movement": value.exposures[5],
                                "freeze-thaw": value.exposures[6],
                                "flood_surfacewater": value.exposures[7],
                                "cyclone_wind": value.exposures[8],
                            }
                        }
                    }
                },
                {}
            )
        }

        // TODO: Change this in acrobase, then delete this.
        if (checkProp(archetype, "specifications") & !checkProp(archetype, "elements")) {
            archetype.elements = archetype.specifications
            // This is not getting deleted?
            delete archetype.specifications
        }


        // TODO: Change this in acrobase, then delete
        if (typeof Object.keys(archetype.materials)[0] != "object") {
            archetype.materials = _.reduce(archetype.materials,
                (out, value, key) => {
                    out[key] ={
                        "flood_riverine": value[0],
                        "inundation": value[1],
                        "heat": value[2],
                        "forest_fire": value[3],
                        "wind": value[4],
                        "soil_movement": value[5],
                        "freeze-thaw": value[6],
                        "flood_surfacewater": value[7],
                        "cyclone_wind": value[8],
                    }
                    return out
                },
                {}
            )
        }

        archCache.archetypes[acronym] = archetype
    }
    return _.cloneDeep(archCache.archetypes[acronym])
}

/**
 * Load and cache Materials data from Acrobase.
 */
const getMaterialsData = async () => {
    // If mobx proxy is empty, load from Acrobase
    if (Object.keys(archCache.materials).length == 0) {
        const materials = await jQuery.ajax({
            // eslint-disable-next-line no-undef
            url: `${crConstants.acrobaseHost}/v1/material_list?expand_aliases=true`,
        })

        // TODO: Acrobase update will re-configure this.
        for (const m in materials) {
            let mat = materials[m]
            let { name, id } = mat

            // TODO: Update these in acrobase
            if (checkProp(mat, "coastal_inundation")) {
                mat["inundation"] = mat.coastal_inundation
                delete mat.coastal_inundation
            }
            if (checkProp(mat, "freeze_thaw")) {
                mat["freeze-thaw"] = mat["freeze_thaw"]
                delete mat["freeze_thaw"]
            }

            delete mat.name
            delete mat.id
            delete mat._id

            mat = {
                name,
                id,
                failure_coefficients: mat,
            }

            archCache.materials[mat.id] = mat
        }
    }
    return archCache.materials
}

// Pre-load materials data
getMaterialsData()

// Returns a mobx observable - good to use as a state/prop
const getMaterials = () => {
    return archCache.materials
}

export { archCache, getArchetype, getArchetypeData, getMaterials, getMaterialsData }
