/**
 * Calculates present value.
 *
 * @param {number} value Initial value
 * @param {number} rate Discount rate
 * @param {number} period Number of time periods
 * @returns {number} Calculated present value
 */
export function calculatePresentValue(value, rate, period) {
    return value / Math.pow(1 + rate, period);
}

/**
 * Calculates net present value (NPV)
 *
 * @param {number[]} values Initial values
 * @param {number} rate Discount rate
 * @returns {number} Calculated NPV
 */
export function calculateNetPresentValue(values, rate) {
    return values.reduce((npv, value, index) => (
        npv + calculatePresentValue(value, rate, index + 1)
    ), 0);
}