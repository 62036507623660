import React from "react";
import autoBind from "react-autobind";
import { Link, Text, View, StyleSheet, Page, Image } from "@react-pdf/renderer";

import styled from "@react-pdf/styled-components";
import {
    Subtitle,
    SubtitleBlack,
    SubtitleLarge,
    Paragraph,
    styles,
    Br1,
} from "../PdfReportComponents/Shared.jsx";

class AnalysisMethods extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    render() {
        return (
            <React.Fragment>
                <Page size="A4" style={styles.customPages}>
                    <View
                        style={[
                            styles.redBackground,
                            { paddingVertical: 10, paddingHorizontal: 10, marginBottom: 10 },
                        ]}
                    >
                        <SubtitleLarge style={styles.whiteText}>
                            4. METHODS USED FOR ANALYSIS
                        </SubtitleLarge>
                    </View>

                    <Br1></Br1>
                    <Paragraph>
                        This analysis covers riverine flooding, surface water flooding, 
                        coastal inundation, forest fires, extreme wind, soil movement due to drought, 
                        tropical cyclone / hurricane wind, and extreme heat. It does not cover 
                        coastal erosion, grass fires, landslip, hail or heat impacts.
                    </Paragraph>
                    <Br1></Br1>
                    <Paragraph>
                        Results have been generated using purpose-built software running on an array
                        of high-speed servers provided by Climate Risk Pty Ltd. The Climate Risk
                        Engines assess climate impacts by placing a pre constructed asset
                        ‘Archetype’ at the address entered. Using the design specifications and
                        materials typical of the Archetype, the Climate Risk Engines compute the
                        threshold at which its various key components would fail if exposed to
                        hazards such as flooding, soil movement and forest fires.
                    </Paragraph>
                    <Br1></Br1>
                    <Paragraph>
                        Using this information, the annual probability of damage being caused by
                        such events is calculated by (a) gathering a range of data on forests,
                        soils, floods, elevations, tides, and waves, (b) coupling this with long
                        term data from local meteorological stations, and (c) adjusting future
                        probabilities to account for changes in parameters such as heat,
                        precipitation, wind and humidity from global climate change models.
                    </Paragraph>
                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Stress-Test Approach</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        This analysis uses an IPCC greenhouse gas emission scenario that follows
                        business-as-usual (RCP8.5). Specific models are selected to ‘stress test’
                        each hazard - thus a model that tends to predict a drier future is used to
                        consider drought, and a model which predicts a wetter future is used to test
                        flood risk. This selection process avoids masking risks or diluting impacts
                        through averaging an ensemble of models, however results should be
                        interpreted as a stress-test, not a mean or median projection
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        Multi-model ensemble analysis is available from XDI upon request.
                    </Paragraph>
                    <Br1></Br1>
                    <Br1></Br1>
                    <Br1></Br1>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>The Climate Risk Engines</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        The Climate Risk Engines are purpose built to compute hypothetical future
                        risks to a modelled asset archetype designed to represent individual
                        property and infrastructure assets. The system enables each such an asset to
                        be stress-tested against a wide range of extreme weather and extreme sea
                        events typical of its location. A range of future-looking scenarios can be
                        applied that are consistent with different greenhouse gas emission
                        scenarios, atmospheric sensitivity, adaptation pathways, building standards
                        and planning regimes.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        The Climate Risk Engines combine engineering analysis with statistical
                        analysis of historical weather and climate projections, and probabilistic
                        methods for financial analysis of risk and value. It’s important to note
                        that these results apply to a synthetic Representative Asset Archetype under
                        a range of future scenarios. The results cannot therefore be taken as
                        representations of the actual future risks to, or value of, a real or
                        planned property or infrastructure asset.
                    </Paragraph>
                    <Br1></Br1>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            A Representative Asset Archetype
                        </Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        Initially the system creates a synthetic representation of an asset that is
                        based on nominal industry archetypes, but may include some customisation by
                        the user. This representative asset type could be selected and tailored to
                        represent a real asset at the same location – such as a house, road or phone
                        tower – or be created as an entirely hypothetical asset being placed in that
                        location.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Included Hazards</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        Weather and climate change are not hazards in themselves. Rain is not a
                        hazard, but flooding is. Drought is not a hazard, but contracting soils and
                        moving foundations can be. Therefore, quite apart from just climate and
                        weather data, the Climate Risk Engines analyse a range of damage causing
                        hazards including riverine and surface water flooding, coastal inundation, forest fires,
                        extreme wind, extreme heat soil movement due to drought and tropical cyclone / hurricane wind.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Mathematical Analysis</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        The extreme weather and climate risks to an asset will depend on its
                        exposure and vulnerability to each hazard, as well as the current and future
                        severity and frequency of the hazard that may alter with climate change. How
                        each of these are handled by the Climate Risk Engines is discussed below.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Hazard Exposure</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        To understand if the Representative Asset is exposed to a hazard or not,
                        contextual information about each location is gathered by the Climate Risk
                        Engines. This may include information about the soils, tree cover, topology,
                        elevation, flood plains, local tides or waves. Data is gathered on these
                        contextual features from national and international sources selected on the
                        basis of scientific methods used, accuracy, spatial resolution, completeness
                        and the standing of the institution that has generated the information. The
                        organisations from which data have been used are set out in the appendix of
                        this document.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>
                            Vulnerability Analysis; Damage and Failure Thresholds
                        </Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        Each asset is tested for its ability to withstand the hazards to which it is
                        likely to be exposed for each future year. The system tests both failure
                        thresholds and damage thresholds.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        A damage threshold is breached when an asset is affected by a hazard such
                        that it is broken or excessively weakened. Examples might be flood waters
                        damaging an electrical control system, or a wind-storm blowing the roof off
                        a house.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        A failure threshold is breached when an element of the asset prevents the
                        asset from performing its function. For example, when the roof is blown off
                        in a storm it is both damaged and it fails to protect its occupants from the
                        weather. However, it is possible to have failure without damage, for example
                        an electrical control system that exceeds its operating temperature in a
                        heat wave may stop the asset working, but there will be no damage (when the
                        temperature drops it will start working again).
                    </Paragraph>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>

                <Page size="A4" style={styles.customPages}>
                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Weather Data</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        To establish the precise probability that a hazard will exceed the coping
                        threshold of an asset or element, information about the causal weather or
                        climate indicators may be needed, for example the likelihood of flooding is
                        linked to the likelihood of extreme precipitation.
                    </Paragraph>
                    <Br1></Br1>
                    <Paragraph>
                        The Climate Risk Engines have access to data from 100,000 weather stations
                        around the world. Internal algorithms are used to select which stations to
                        use when testing an asset, based on proximity, data quality, duration and
                        completeness. In some cases, the Climate Risk Engines may use a combination
                        of data from more than one station or gridded data sets made by national
                        meteorological centers. Satellite data has become an important source of
                        weather data and this too may be accessed for computations, though the
                        climate change influence on a weather data base-line has to be handled very
                        carefully.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Climate Change Modelling</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        Changes in the composition of the atmosphere due to greenhouse gas emissions
                        will change how the atmosphere and oceans behave. Therefore, the historical
                        weather station statistics need to be adjusted to allow for climate change.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        The Climate Risk Engines have access to a large number of data sets from the
                        Coupled Model Inter-comparison Project (CMIP) in which participant
                        organisations model the atmosphere under various Representative
                        Concentration Scenarios (RCP). At a whole of atmosphere scale the General
                        Circulation Models (GCMs) have a resolution down to about 100km3.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        With downscaling, Regional Climate Models (RCMs) include local topology and
                        land surface information to provide weather parameters at higher spatial
                        resolutions - between 5km3 and 50km3.
                    </Paragraph>

                    <Br1></Br1>
                    <Paragraph>
                        For some locations, users can select the GCM/RCM they wish to apply to the
                        analysis of the Representative Asset, otherwise the Climate Risk Engines
                        will select by default the most appropriate climate modelling to use based
                        on: the models available in the region; the ‘skill’ of the model in
                        capturing typical weather behaviour in a certain region; the range of
                        parameters included or reported; the spatial resolution; and how the results
                        of the model fit within the ensemble of other models for the region.
                    </Paragraph>

                    <Br1></Br1>
                    <Br1></Br1>

                    <View style={styles.lightGreyBackground}>
                        <Subtitle style={styles.whiteText}>Scenario Run Data</Subtitle>
                    </View>
                    <Br1></Br1>
                    <Paragraph>
                        In this analysis data from CORDEX is used. CORDEX models are selected based
                        on resolution, currency and relevance the hazard of interest. The model
                        selected for each hazard is specified in the results.
                    </Paragraph>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </Page>
            </React.Fragment>
        );
    }
}

export default AnalysisMethods;
