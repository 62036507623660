import React from "react"
import autoBind from "react-autobind"
import ReactGA from "react-ga";

const StreetViewDelay = 0

class StreetView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
        }

        autoBind(this)
    }

    componentDidUpdate() {
        const analysis = {
            ...this.props.analysis,
            meta: { collection: "Point" },
        }
        this.tabLocation(analysis)
    }

    componentDidMount() {
        const analysis = {
            ...this.props.analysis,
            meta: { collection: "Point" },
        }

        if (crConstants.production) ReactGA.modalview("/analysis-result/streetview-tab")

        this.tabLocation(analysis)
    }

    // TODO: Add button to open real streetView. Just change state StaticImage to false
    tabLocation(analysis) {
        setTimeout(function () {
            // create instance of street view service
            var property = new google.maps.LatLng(
                analysis.geometry.coordinates[1],
                analysis.geometry.coordinates[0]
            )
            let sv = new google.maps.StreetViewService()
            // Set the initial Street View camera to the center of the map
            sv.getPanorama(
                {
                    location: property,
                    radius: 75,
                    preference: google.maps.StreetViewPreference.NEAREST,
                    source: google.maps.StreetViewSource.OUTDOOR,
                },
                function (data, status) {
                    if (status === "OK") {
                        var heading = google.maps.geometry.spherical.computeHeading(
                            data.location.latLng,
                            property
                        )
                        // init the panorama

                        // TODO add in static image if needed
                        // if (staticImage) {
                        //   document.getElementById("pano").innerHTML = `<img src="https://maps.googleapis.com/maps/api/streetview?size=${document.getElementById("pano").clientWidth}x${500}&location=${analysis.geometry.coordinates[1]},${analysis.geometry.coordinates[0]}&fov=100&heading=${heading}&pitch=0&source=outdoor&key=${crConstants.GoogleApiKey}" />`
                        // } else {
                        var panorama = new google.maps.StreetViewPanorama(
                            document.getElementById("pano"),
                            {
                                zoom: 1,
                                disableDefaultUI: true,
                            }
                        )

                        panorama.setPano(data.location.pano)
                        panorama.setPov({
                            heading: heading,
                            pitch: 0,
                        })

                        panorama.setVisible(true)
                    } else {
                        document.getElementById("pano").innerHTML =
                            "There is no Street View data for this location."
                    }
                }
            )
        }, StreetViewDelay)
    }

    render() {
        return (
            <React.Fragment>
                <div id="pano"> Loading... </div>
            </React.Fragment>
        )
    }
}

export default StreetView
